/* You can add global styles to this file, and also import other style files */
/* libraries styles are under project.json > build > options > styles section */

@import '_components/_variables';
@import '_components/_mixins';

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

// TODO unify sidenav styles with other apps
.aside-menu-holder {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: $body-main-font;
}

// Default scrollbar style
*::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 1px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

// Form Validation
form.ng-submitted {
  .pnkl-validation-errors {
    display: block;
  }

  input.pnkl-input,
  kendo-combobox.k-combobox,
  kendo-datepicker.k-datepicker,
  kendo-datetimepicker.k-datetimepicker,
  kendo-timepicker.k-timepicker {
    &.ng-invalid {
      border-bottom: 1px solid #f74053;
    }
  }
}

// Kendo Dialog
.k-dialog {
  .k-dialog-titlebar {
    background-color: #eae8e8;
    color: #313131;

    .k-dialog-title {
      font-size: 16px;
      overflow: unset;
    }
  }
}

.reconnect-window kendo-dialog .k-dialog-actions {
  display: none;
}

// Kendo Window
.k-window {
  .k-window-titlebar {
    background-color: $geyser;
    color: #313131;

    .k-window-title {
      font-size: 16px;
      overflow: unset;
    }
  }
}

// Kendo Date Picker AngularJS Styling Fix
.k-widget.k-datepicker.k-header,
.k-widget.k-timepicker.k-header {
  .k-picker-wrap.k-state-default span.k-select {
    background-image: none;
    background-color: transparent;
  }
}

//  Fix for pinnakl grid action buttons conflicting with kendo command buttons
.action-button .k-button.action-icon {
  min-width: 0;
}

input.pnkl-input,
textarea.pnkl-input {
  border: none;
  background: transparent;
  display: block;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #6e7c8f;

  &.dark {
    color: #fff;
  }
}

input.pnkl-input {
  height: 30px;
}

textarea.pnkl-input {
  resize: none;
}

// Kendo Datepicker/Timepicker changes to match Pinnakl theme
kendo-datepicker.k-datepicker,
kendo-datetimepicker.k-datetimepicker,
kendo-timepicker.k-timepicker {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #6e7c8f;
  border-radius: 0;
  color: inherit;

  &:hover {
    background-color: transparent;
  }

  span.k-picker-wrap {
    background: transparent;
    border: 0;

    &:hover,
    &:focus,
    &.k-state-focused {
      background: transparent;
      border: 0;

      kendo-dateinput span.k-dateinput-wrap {
        background: transparent;
        border: 0;

        input {
          padding: 0;
        }
      }

      .k-select {
        background: transparent none;
      }
    }

    kendo-dateinput span.k-dateinput-wrap {
      background: transparent;
      border: 0;

      input {
        padding: 0;
      }
    }

    .k-select {
      background: transparent none;
    }

    .k-dateinput {
      background: transparent;
      border: 0;
    }
  }

  span.k-picker-wrap.k-state-focused .k-input {
    border: 0;
  }

  .k-button-solid-base:hover,
  .k-button-solid-base.k-hover {
    background-color: transparent;
  }
}

// Add class 'datepicker-dark' to filter datepickers
// E.g. <kendo-datepicker [(value)]="date" class="datepicker-dark">
kendo-datepicker.k-datepicker.datepicker-dark,
kendo-timepicker.k-timepicker.timepicker-dark {
  border-bottom: 1px solid #7e8c9e;

  span.k-icon.k-i-calendar {
    color: #7e8c9e;
  }

  kendo-dateinput.k-dateinput {
    color: #fff;
  }
}

kendo-datepicker.k-datepicker.popup-left-margin-adjust {
  kendo-popup {
    margin-left: -100px;
  }
}

// Kendo Combobox changes to match Pinnakl theme
kendo-combobox.k-combobox {
  border: 0;
  background: transparent;
  border-bottom: 1px solid #6e7c8f;
  border-radius: 0;
  width: 100%;

  span.k-dropdown-wrap {
    border: 0;
    background: transparent;
    box-shadow: none;

    span.k-clear-value {
      right: 1.4285714286em;
    }

    input.k-input {
      padding: 0;
    }
  }

  span.k-select {
    background: transparent;
  }

  &.k-combobox-clearable span.k-dropdown-wrap .k-input {
    padding: 0 13px 0 0;
  }

  &.k-widget.ng-invalid .k-dropdown-wrap {
    color: inherit;
  }

  &:focus-within {
    box-shadow: none;
    border-bottom: 1px solid #6e7c8f;
  }

  &.ng-invalid {
    border-bottom: 1px solid #6e7c8f;
  }

  &.ng-invalid.ng-touched {
    border-bottom: 1px solid #f74053;
  }
}

kendo-combobox.k-combobox:hover span.k-select {
  background: transparent;
}

.text-uppercase kendo-combobox input {
  text-transform: inherit;
}

// Add class 'pnkl-select-dark' to filter comboboxes
// E.g. <pnkl-select [options]="options" [(ngModel)]="selectedOption" class="pnkl-select-dark"></pnkl-select>
.pnkl-select-dark kendo-combobox.k-combobox {
  border-bottom: 1px solid #7e8c9e;

  span.k-dropdown-wrap {
    color: #fff;
  }

  span.k-select {
    color: #7e8c9e;
  }
}

.k-multiselect,
.k-dropdowntree {
  .k-multiselect-wrap {
    padding: 0 calc(1.4285714286em + 16px) 0 0;
  }

  .k-multiselect-wrap,
  .k-multiselect-wrap:hover {
    .k-clear-value {
      right: 20px;
    }
  }
}

.pnkl-select-dark kendo-multiselect.k-multiselect {
  background-color: transparent;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #7e8c9e;

  .k-clear-value {
    right: 20px;
  }

  .k-button {
    background-color: #38485a;
    color: #fff;
    background-image: none;

    span {
      text-transform: uppercase;
      font-weight: 200;
      color: #ffffff;
    }

    .k-i-close {
      color: #fff;
      opacity: 1;
    }
  }

  .k-i-loading {
    background-color: transparent;
  }
}

kendo-grid.k-grid {
  .k-grid-header th.k-header {
    padding: 6px;
  }

  td {
    padding: 2px 6px;
    border-bottom: 1px solid #e4e9f0;

    input.k-textbox {
      margin: 1px 0 4px;
    }
  }
}

.pricing-grid kendo-grid.k-grid td {
  font-size: 13px;
}

.pricing-grid .ag-row-group .action-col > * {
  display: none;
}

.pricing-grid .ag-cell-wrapper.ag-row-group {
  align-items: center;
}

.width-13-percent {
  width: 13%;
}

.width-7-percent {
  width: 7%;
}

.width-30-percent {
  width: 30%;
}

.width-10-percent {
  width: 10%;
}

.width-25-percent {
  width: 25%;
}

.width-50-percent {
  width: 50%;
}

.width-5-percent {
  width: 5%;
}

.height-8 {
  height: 8rem;
}

.k-grid.fixed-header-grid {
  thead,
  tbody,
  tfoot {
    display: block;
  }

  tbody,
  tfoot {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: auto;

    td:last-child {
      border-right: 1px solid #e4e9f0;
    }
  }
}

.k-grid tr.k-alt {
  background-color: #fff !important;
}

.visibility-hidden {
  visibility: hidden;
}

.reporting-filter-height {
  height: calc(100vh - 58px - 12rem);
  overflow: auto;
}

.grid-filter-columns {
  border: 1px solid #0b1017;
  background: #1f2832;
  overflow-y: auto;

  input {
    color: #eaecef;
  }

  .mCustomScrollBox {
    width: 100%;
  }
}

.display-block {
  display: block !important;
}

.display-none {
  display: none;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.max-height-80vh {
  max-height: 80vh;
}

.ag-theme-business {
  // Add EMS statuses
  [class*='ems-status'] {
    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 2px 6px 2px 2px;
    }
  }

  //  | 'PartialFill' - green
  //  | 'Rejected' - red
  //  | 'Filled' - blue
  //  | 'DoneForDay' - blue
  //  | 'Canceled' - blue
  //  | 'Expired' - blue
  //  | 'Stopped' - blue
  //  | 'New' - black
  //  | 'Replaced' = black
  //  | 'Calculated' - black
  //  | 'Restated' - black
  //  | 'PendingCancel' - orange
  //  | 'PendingNew' - orange
  //  | 'PendingReplace' - orange
  //  | 'Suspended' - blue
  // custom
  //  | 'Placed' - black
  //  | 'Draft' - gray
  //  | 'Staged' - gray
  //  | 'Placement Pending' - orange
  .ag-cell {
    &.ems-status-filled:before,
    &.ems-status-fill:before,
    &.ems-status-canceled:before,
    &.ems-status-stopped:before,
    &.ems-status-expired:before,
    &.ems-status-suspended:before,
    &.ems-status-doneforday:before {
      background-color: $active-status-text-color;
    }

    &.ems-status-rejected:before {
      background-color: $rejected-status-text-color;
    }

    &.ems-status-pending:before,
    &.ems-status-placement-pending:before,
    &.ems-status-pending-new:before,
    &.ems-status-pending-cancel:before,
    &.ems-status-pending-replace:before {
      background-color: $pending-status-text-color;
    }

    &.ems-status-replace:before,
    &.ems-status-partialfill:before {
      background-color: $partial-fill-status-text-color;
    }

    // black
    &.ems-status-new:before,
    &.ems-status-placed:before,
    &.ems-status-replaced:before,
    &.ems-status-restated:before,
    &.ems-status-calculated:before {
      background-color: $black;
    }

    // gray
    &.ems-status-staged:before,
    &.ems-status-draft:before {
      background-color: $inactive-status-text-color;
    }
  }
}

.ems-trantype-tag {
  border-radius: 100px;
  background-color: #18d06b;
  color: #fff;
  min-width: 50px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  padding: 0 6px 2px;

  &.red {
    background-color: #ff4949;
  }

  &.orange {
    background-color: #ff8b2a;
  }

  &.blue {
    background-color: #168eff;
  }
}

.ag-cell.circle {
  display: flex;
  justify-content: center;

  div {
    width: 50px;
    display: flex;
  }
}

.cell-chart {
  margin-bottom: 2px;
  margin-right: 3px;

  .circle-chart__circle {
    animation: circle-chart-fill 1s reverse;
    transform: rotate(-90deg);
    transform-origin: center;
  }

  @keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }
}

.FS20 {
  font-size: 20px !important;
}

.risk-filter {
  right: -16px !important;
  top: 43px !important;
}

.width100 {
  width: 100% !important;
}

.color-grey {
  color: #7e8c9e;
}

.oms-side-panel-border {
  border-left: 2px solid #293542;
}

.request-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;

  .request-left {
    width: 65% !important;
    background-color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  .request-right {
    width: 35% !important;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

.paddingLR0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.padding20 {
  padding: 20px;
}

.ellipse-highlighter {
  background-color: #e7ebf0;
  padding: 3px 5px;
  border-radius: 40px;
  color: rgb(126, 140, 158);
}

.report-card-ul li a.ellipse-highlighter {
  color: rgb(126, 140, 158) !important;
}

.grid-align {
  text-align: right;
}

.white-space-pre-line {
  white-space: pre-line;
}

.border-default {
  border: 1px solid black;
}

.center-aligned-row {
  justify-content: center;
}

.right-aligned-row {
  justify-content: flex-end;
}

.word-break-all {
  word-break: break-all;
}

.color-blue {
  color: $blue !important;
}

// bootstrap latest classes -- remove css below when upgrade from bootstrap alpha to a stable and latest version
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.h-100 {
  height: 100% !important;
}

.font-weight-bold {
  font-weight: bold;
}

.text-capitalize {
  text-transform: capitalize;
}

.px-5px {
  padding-left: 5px;
  padding-right: 5px;
}

// End of bootstrap latest classes -- remove css above when upgrade from bootstrap alpha to a stable and latest version

// for cash-flow only, need to make separately in shared
.cash-flow-grid {
  height: 800px;
}

.z-index-10 {
  z-index: 10;
}

.FS18 {
  font-size: 18px !important;
}

.right-align {
  text-align: right;
}

.text-upper-case {
  text-transform: uppercase;
}

.layout-align-center {
  display: flex;
  align-items: center;
}

.background-color-white {
  background-color: #fff;
}

.horizontal-align-center {
  display: flex;
  justify-content: center;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-16px {
  font-size: 16px;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.direction-tag {
  border-radius: 10px;
  background-color: #d9dde1;
  padding: 2px 10px;
}

.bg-color-light-blue {
  background-color: lightblue !important;
}

.bottom-border-blue {
  border-bottom: blue solid 1px;
}

kendo-window .k-window-content {
  border: 1px solid #dce2e8;
}

.k-window.k-dialog {
  border-radius: 8px;
}

kendo-dialog-titlebar.k-window-titlebar.k-dialog-titlebar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-scroll-light {
  .optiscroll-vtrack,
  .optiscroll-htrack {
    background: rgba(247, 245, 245, 0.3);
  }
}

.pointer {
  cursor: pointer;
}

.margin-top-5px {
  margin-top: 5px;
}

.dropdown-content-align,
.dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: unset !important;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.password-change-dropdown-align,
.notification-dropdown-align {
  left: -95px;
  top: 60px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.badge {
  padding: 0;
}

.upload-button {
  height: 35px;
}

.tree-map-category-label {
  margin-top: -3px !important;
}

.pnl-yearly-grid {
  width: 100%;
  height: 750px;
}

.contact-bulk-upload-grid {
  height: 600px;
}

.cell-wrap-text {
  white-space: normal !important;
}

.crm-contacts-upload-container .page-col-slider-container {
  height: 100%;
}

.tracking-link-file-container {
  .pnkl-validation-errors {
    display: block !important;
  }
}

.investor-returns-grid {
  height: 450px;
}

.target-funds-grid {
  height: 335px;
}

.payment-history-grid {
  max-height: 45vh;
  overflow-y: auto;
}

.communications-all-investors {
  .section-container {
    height: 50vh !important;
  }

  .communication-content-container {
    background: #f1f1f1 !important;
  }
}

.investor-search-remove {
  position: absolute;
  right: 15px;
  top: 20px;
}

.newOrderModal .k-dialog {
  border-radius: 8px;
}

// EMS Order statuses
.order-status {
  font-size: 12px;
  font-weight: 600;
  color: $status-text-color;
  //  | 'PartialFill' - green
  //  | 'Rejected' - red
  //  | 'Filled' - blue
  //  | 'DoneForDay' - blue
  //  | 'Canceled' - blue
  //  | 'Expired' - blue
  //  | 'Stopped' - blue
  //  | 'New' - black
  //  | 'Replaced' = black
  //  | 'Calculated' - black
  //  | 'Restated' - black
  //  | 'PendingCancel' - orange
  //  | 'PendingNew' - orange
  //  | 'PendingReplace' - orange
  //  | 'Suspended' - orange
  // custom
  //  | 'Placed' - black
  //  | 'Draft' - gray
  //  | 'Staged' - gray
  //  | 'Placement Pending' - orange
  &.badge {
    background-color: $status-bg-color;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 2px 12px;
    border-style: solid;
    border-width: 2px;
    border-radius: 15px;
    border-color: transparent;

    &.partialfill {
      background-color: $white;
      border-color: $partial-fill-status-text-color;
    }

    &.canceled,
    &.doneforday,
    &.stopped,
    &.filled,
    &.expired {
      background-color: $white;
      border-color: $active-status-text-color;
    }

    &.rejected {
      background-color: $white;
      border-color: $rejected-status-text-color;
    }

    &.pendingcancel,
    &.pendingnew,
    &.pendingreplace,
    &.suspended &.pending {
      background-color: $white;
      border-color: $pending-status-text-color;
    }

    &.replaced,
    &.calculated,
    &.restated,
    &.placed {
      background-color: $white;
      border-color: $black;
    }

    &.draft,
    &.staged {
      background-color: $white;
      border-color: $inactive-status-text-color;
    }
  }

  &.partialfill {
    color: $partial-fill-status-text-color;
  }

  &.canceled,
  &.doneforday,
  &.stopped,
  &.filled,
  &.expired {
    color: $active-status-text-color;
  }

  &.rejected {
    color: $rejected-status-text-color;
  }

  &.pendingcancel,
  &.pendingnew,
  &.pendingreplace,
  &.suspended &.pending {
    color: $pending-status-text-color;
  }

  &.replaced,
  &.calculated,
  &.restated,
  &.placed {
    color: $black;
  }

  &.draft,
  &.staged {
    color: $inactive-status-text-color;
  }
}

.positions-slide-right {
  position: fixed;
  left: auto;
  right: 0;
  transform: translateX(100%);
  z-index: 10;
}

.right-side {
  &.allocations-width-1 .max-col {
    @include respond-to(desktops) {
      max-width: 21%;
    }
  }

  &.allocations-width-3 .max-col {
    max-width: 18% !important;
    @include respond-to(desktops) {
      max-width: 18% !important;
    }

    + .max-col {
      max-width: 13% !important;
      @include respond-to(desktops) {
        max-width: 13% !important;
      }
    }
  }
}

.info-container {
  mark {
    background-color: #168eff33;
    padding: 0;
  }
}

.mat-mdc-dialog-container {
  position: relative;
  padding-top: 0 !important;
}

.modal-dialog-radius-12 .mat-mdc-dialog-surface {
  border-radius: 12px;
}

.modal-dialog-radius-10 .mat-mdc-dialog-surface {
  border-radius: 10px;
}

.modal-dialog-overflow-y-clip .mat-mdc-dialog-container {
  overflow-y: clip;
}

.modal-dialog-full-height {
  mat-pinnakl-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-dialog-content {
    height: 100%;
  }
}

.menu-trigger {
  position: absolute;
  left: 25px;

  .hidden-label {
    opacity: 0;
  }
}

.menu-commission {
  .mat-mdc-menu-item {
    &.selected {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.h-full {
  height: 100% !important;
}

.size-1 {
  width: 1px;
  height: 1px;
}
