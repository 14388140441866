@use './spacings' as sp;
@import 'variables';

.table-layout-fixed {
  table-layout: fixed;
}

.box-shadow-none {
  box-shadow: none !important;
}

.border-round-20px {
  border-radius: 20px;
}

.border-round-100 {
  border-radius: $superRadius;
}

.tracking-sm {
  letter-spacing: 0.88px;
}

.border-error {
  border-color: $errorColor;
}

.-scale-x-1 {
  transform: scaleX(-1);
}

//line-height / width / height
$sizes: 0, 1, 2, 3, 4, 5;
// for partial output will be for example: w-1_25rem, w-1_50rem, w-1_75rem, py-1_625rem
$partial-sizes: 0.25, 0.5, 0.75;
$sub-partial-sizes: 0.125, 0.375, 0.625, 0.875;

$map: $partial-sizes, $sub-partial-sizes;

@each $size in $sizes {
  .line-height-#{$size}rem {
    line-height: $size * 1rem !important;
  }
  .min-w-#{$size}rem {
    min-width: $size * 1rem !important;
  }
  .min-h-#{$size}rem {
    min-height: $size * 1rem !important;
  }
  .max-w-#{$size}rem {
    max-width: $size * 1rem !important;
  }
  .max-h-#{$size}rem {
    max-height: $size * 1rem !important;
  }
  .gap-#{$size}rem {
    gap: $size * 1rem !important;
  }

  @for $i from 1 through length($map) {
    $partly-sizes: nth($map, $i);
    @each $partly-size in $partly-sizes {
      $value: $partly-size * 100;
      @if $i == 2 {
        $value: $partly-size * 1000;
      }
      .line-height-#{$size}_#{$value}rem {
        line-height: ($size + $partly-size) * 1rem !important;
      }
      .w-#{$size}_#{$value}rem {
        width: ($size + $partly-size) * 1rem !important;
      }
      .min-w-#{$size}_#{$value}rem {
        min-width: ($size + $partly-size) * 1rem !important;
      }
      .max-w-#{$size}_#{$value}rem {
        max-width: ($size + $partly-size) * 1rem !important;
      }
      .h-#{$size}_#{$value}rem {
        height: ($size + $partly-size) * 1rem !important;
      }
      .min-h-#{$size}_#{$value}rem {
        min-height: ($size + $partly-size) * 1rem !important;
      }
      .max-h-#{$size}_#{$value}rem {
        max-height: ($size + $partly-size) * 1rem !important;
      }
      .gap-#{$size}_#{$value}rem {
        gap: ($size + $partly-size) * 1rem !important;
      }
      // margins/paddings
      // for partial output will be for example: m-1_25rem, mb-1_50rem, pt-1_75rem,
      // for sub-partial output will be for example: m-0_625rem, px-1_875rem,
      @include sp.spacing-classes('p', sp.$padding-directions, $size, $partly-size, $value);
      @include sp.spacing-classes('m', sp.$margin-directions, $size, $partly-size, $value);
    }
  }
}

// 1px
.w-0 {
  width: 0 !important;
}

.h-0 {
  height: 0 !important;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-1\/2-screen {
  max-width: 50vw;
}

.max-h-1\/2 {
  max-height: 50%;
}

.max-h-1\/2-screen {
  max-height: 50vh;
}

// margins/paddings for 1px; mb-1_0px etc
@include sp.spacing-classes('m', sp.$margin-directions, 1, -0.9375, '0', 'px');
@include sp.spacing-classes('p', sp.$padding-directions, 1, -0.9375, '0', 'px');

$clamp-lines: 1, 2, 3, 4, 5, 6;
@mixin text-ellipsis-lines($line, $break-type: break-word) {
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: $break-type !important;
  overflow: hidden;
}

@each $line in $clamp-lines {
  .text-ellipsis-line-#{$line} {
    @include text-ellipsis-lines($line);
  }
  .text-ellipsis-break-all-line-#{$line} {
    @include text-ellipsis-lines($line, break-all);
  }
}

$font-sizes: 10, 11, 13;
@each $size in $font-sizes {
  .font-size-#{$size}px {
    font-size: $size * 1px !important;
  }
}

$big-sizes: 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  30, 32, 34, 36, 29, 30;
@each $size in $big-sizes {
  .min-h-#{$size}rem {
    min-height: $size * 1rem !important;
  }
  .max-h-#{$size}rem {
    max-height: $size * 1rem !important;
  }
  .min-w-#{$size}rem {
    min-width: $size * 1rem !important;
  }
  .max-w-#{$size}rem {
    max-width: $size * 1rem !important;
  }
}

@mixin noScroll() {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.hidden-scrollbar {
  @include noScroll();
}

.z-index-1000 {
  z-index: 1000 !important;
}

.-translate-y-50 {
  transform: translateY(-50%) !important;
}

.-translate-x-50 {
  transform: translateX(-50%) !important;
}

.hover\:opacity-100:hover {
  opacity: 100% !important;
}

.bg-deepblue-300 {
  background-color: $deepBlue;
}

.bg-mellow-mint {
  background-color: $mellowMint;
}

// Needed for HighlightDirective (libs/shared/ui/directives-and-pipes)
[pinnaklhighlight] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit !important;
  }

  mark {
    background-color: transparent;
    line-height: 1;
    padding: 0;
  }
}

// Content overflow bottom overlay
.content-bottom-faded {
  &:after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(180deg, transparent 0%, transparent 60%, rgba(#ffffff, 1) 100%);
  }

  &.bg-orange-100:after {
    background: linear-gradient(
      180deg,
      transparent 0%,
      transparent 60%,
      rgba(var(--orange-100), 1) 100%
    );
  }
}

.not-visible {
  visibility: hidden !important;
}

.bg-none {
  background: none;
}

.text-error {
  color: var(--error-color);
}

// HiddenChildrenCountObserverDirective classes
.chips-container {
  flex-wrap: nowrap !important;

  .chip {
    // counter reserved size + 8px gap between chips + additional 2px
    max-width: calc(100% - 50px);
  }

  .counter {
    max-width: 50px;
  }

  &.show-chips {
    flex-wrap: wrap !important;
    max-height: initial !important;

    .chip {
      max-width: 100%;
    }

    .not-visible {
      visibility: visible !important;
    }
  }
}
