@import 'variables';

.bordered-list-style-table {
  &.ag-theme-business {
    --ag-data-color: #{$textBlack};
  }

  // For showing icons on hover
  &.with-hover-icons {
    .ag-row {
      .on-hover-icons {
        padding: 0 4px;

        i {
          visibility: hidden;
        }
      }

      &:hover {
        cursor: pointer;
        transition: all 0.4s;
        background: var(--bluegray-50);
        border: 1px solid var(--bluegray-500);
        box-shadow: 0 1px 3px 0 var(--bluegray-200);

        .on-hover-icons i {
          visibility: visible;
        }
      }
    }
  }

  .ag-root-wrapper {
    .ag-header-cell.no-resize-splitter {
      &:before {
        display: none;
      }
    }

    .ag-header-cell {
      &:hover {
        background: none !important;
      }
    }

    .ag-header-cell-text {
      font-size: 11px;
      letter-spacing: 0.08em;
      font-weight: 600;
      line-height: 1rem;
      color: var(--bluegray-700);
    }

    .ag-root-wrapper-body,
    .ag-header {
      border: none;
    }

    .ag-ltr .ag-header-cell::after {
      display: none;
    }

    .ag-row {
      transition: all 0.4s;
      margin-top: 8px;
      border-radius: 12px;
      border: 1px solid var(--bluegray-200);

      &:before {
        border-radius: 12px;
      }

      .ag-cell {
        border: none;
      }
    }

    .ag-group-value {
      align-self: center;
    }
  }
}
