:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #262a32;
  --text-color-secondary: #646f85;
  --layout-black: #0b1017;
  --primary-color: #2196f3;
  --primary-500: #168eff;
  --primary-color-text: #ffffff;
  --font-family: "Source Sans Pro", sans-serif;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f6f6f6;
  --surface-200: #ececec;
  --surface-300: #dddddd;
  --surface-400: #cbcbcb;
  --surface-500: #aaaaaa;
  --surface-600: #959595;
  --surface-700: #6f6f6f;
  --surface-800: #3c3c3c;
  --surface-900: #292929;
  --red-50: #fff9f9;
  --red-100: #fff3f3;
  --red-200: #ffe7e7;
  --red-300: #ffd2d2;
  --red-400: #ffbaba;
  --red-500: #ff8686;
  --red-600: #ff5e5e;
  --red-700: #e10000;
  --red-800: #7f0000;
  --red-900: #5b0000;
  --bluegray-50: #f9fafb;
  --bluegray-100: #f4f5f7;
  --bluegray-200: #eaecef;
  --bluegray-300: #dadde3;
  --bluegray-400: #c7ccd5;
  --bluegray-500: #a1a9b9;
  --bluegray-600: #8b95a8;
  --bluegray-700: #646f85;
  --bluegray-800: #373d49;
  --bluegray-900: #262a32;
  --gray-50: #f9fafb;
  --gray-100: #f4f5f7;
  --gray-200: #eaecef;
  --gray-300: #dadde3;
  --gray-400: #c7ccd5;
  --gray-500: #a1a9b9;
  --gray-600: #8b95a8;
  --gray-700: #646f85;
  --gray-800: #373d49;
  --gray-900: #262a32;
  --blue-50: #f7fbff;
  --blue-100: #eef7ff;
  --blue-200: #dceeff;
  --blue-300: #c1e1ff;
  --blue-400: #a0d1ff;
  --blue-500: #59afff;
  --blue-600: #2a98ff;
  --blue-700: #006ed5;
  --blue-800: #003c74;
  --blue-900: #002950;
  --green-50: #f5fcf7;
  --green-100: #eaf9ef;
  --green-200: #d5f3df;
  --green-300: #b1e9c5;
  --green-400: #89dea7;
  --green-500: #36c167;
  --green-600: #30aa5a;
  --green-700: #247f44;
  --green-800: #134525;
  --green-900: #0d2f19;
  --orange-50: #fffaf4;
  --orange-100: #fff4e8;
  --orange-200: #ffe9d0;
  --orange-300: #ffd6a7;
  --orange-400: #ffbf76;
  --orange-500: #ff8c09;
  --orange-600: #e37900;
  --orange-700: #aa5b00;
  --orange-800: #5c3100;
  --orange-900: #3f2200;
  --yellow-500: #ffb200;
  --indigo-400: #8183f4;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 8px;
  --super-radius: 100px;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #e3f2fd;
  --highlight-text-color: #495057;
  --focus-ring: none;
  --input-border-color: #c9c9cc;
  --error-color: #ff0000;
  color-scheme: light;
}

.table-layout-fixed {
  table-layout: fixed;
}

.box-shadow-none {
  box-shadow: none !important;
}

.border-round-20px {
  border-radius: 20px;
}

.border-round-100 {
  border-radius: 100px;
}

.tracking-sm {
  letter-spacing: 0.88px;
}

.border-error {
  border-color: #e4677e;
}

.-scale-x-1 {
  transform: scaleX(-1);
}

.line-height-0rem {
  line-height: 0rem !important;
}

.min-w-0rem {
  min-width: 0rem !important;
}

.min-h-0rem {
  min-height: 0rem !important;
}

.max-w-0rem {
  max-width: 0rem !important;
}

.max-h-0rem {
  max-height: 0rem !important;
}

.gap-0rem {
  gap: 0rem !important;
}

.line-height-0_25rem {
  line-height: 0.25rem !important;
}

.w-0_25rem {
  width: 0.25rem !important;
}

.min-w-0_25rem {
  min-width: 0.25rem !important;
}

.max-w-0_25rem {
  max-width: 0.25rem !important;
}

.h-0_25rem {
  height: 0.25rem !important;
}

.min-h-0_25rem {
  min-height: 0.25rem !important;
}

.max-h-0_25rem {
  max-height: 0.25rem !important;
}

.gap-0_25rem {
  gap: 0.25rem !important;
}

.p-0_25rem {
  padding: 0.25rem !important;
}

.pt-0_25rem {
  padding-top: 0.25rem !important;
}

.pr-0_25rem {
  padding-right: 0.25rem !important;
}

.pl-0_25rem {
  padding-left: 0.25rem !important;
}

.pb-0_25rem {
  padding-bottom: 0.25rem !important;
}

.px-0_25rem {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-0_25rem {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.-p-0_25rem {
  padding: -0.25rem !important;
}

.-pt-0_25rem {
  padding-top: -0.25rem !important;
}

.-pr-0_25rem {
  padding-right: -0.25rem !important;
}

.-pl-0_25rem {
  padding-left: -0.25rem !important;
}

.-pb-0_25rem {
  padding-bottom: -0.25rem !important;
}

.-px-0_25rem {
  padding-left: -0.25rem !important;
  padding-right: -0.25rem !important;
}

.-py-0_25rem {
  padding-top: -0.25rem !important;
  padding-bottom: -0.25rem !important;
}

.m-0_25rem {
  margin: 0.25rem !important;
}

.mt-0_25rem {
  margin-top: 0.25rem !important;
}

.mr-0_25rem {
  margin-right: 0.25rem !important;
}

.ml-0_25rem {
  margin-left: 0.25rem !important;
}

.mb-0_25rem {
  margin-bottom: 0.25rem !important;
}

.mx-0_25rem {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-0_25rem {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.-m-0_25rem {
  margin: -0.25rem !important;
}

.-mt-0_25rem {
  margin-top: -0.25rem !important;
}

.-mr-0_25rem {
  margin-right: -0.25rem !important;
}

.-ml-0_25rem {
  margin-left: -0.25rem !important;
}

.-mb-0_25rem {
  margin-bottom: -0.25rem !important;
}

.-mx-0_25rem {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-my-0_25rem {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.line-height-0_50rem {
  line-height: 0.5rem !important;
}

.w-0_50rem {
  width: 0.5rem !important;
}

.min-w-0_50rem {
  min-width: 0.5rem !important;
}

.max-w-0_50rem {
  max-width: 0.5rem !important;
}

.h-0_50rem {
  height: 0.5rem !important;
}

.min-h-0_50rem {
  min-height: 0.5rem !important;
}

.max-h-0_50rem {
  max-height: 0.5rem !important;
}

.gap-0_50rem {
  gap: 0.5rem !important;
}

.p-0_50rem {
  padding: 0.5rem !important;
}

.pt-0_50rem {
  padding-top: 0.5rem !important;
}

.pr-0_50rem {
  padding-right: 0.5rem !important;
}

.pl-0_50rem {
  padding-left: 0.5rem !important;
}

.pb-0_50rem {
  padding-bottom: 0.5rem !important;
}

.px-0_50rem {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-0_50rem {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.-p-0_50rem {
  padding: -0.5rem !important;
}

.-pt-0_50rem {
  padding-top: -0.5rem !important;
}

.-pr-0_50rem {
  padding-right: -0.5rem !important;
}

.-pl-0_50rem {
  padding-left: -0.5rem !important;
}

.-pb-0_50rem {
  padding-bottom: -0.5rem !important;
}

.-px-0_50rem {
  padding-left: -0.5rem !important;
  padding-right: -0.5rem !important;
}

.-py-0_50rem {
  padding-top: -0.5rem !important;
  padding-bottom: -0.5rem !important;
}

.m-0_50rem {
  margin: 0.5rem !important;
}

.mt-0_50rem {
  margin-top: 0.5rem !important;
}

.mr-0_50rem {
  margin-right: 0.5rem !important;
}

.ml-0_50rem {
  margin-left: 0.5rem !important;
}

.mb-0_50rem {
  margin-bottom: 0.5rem !important;
}

.mx-0_50rem {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-0_50rem {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.-m-0_50rem {
  margin: -0.5rem !important;
}

.-mt-0_50rem {
  margin-top: -0.5rem !important;
}

.-mr-0_50rem {
  margin-right: -0.5rem !important;
}

.-ml-0_50rem {
  margin-left: -0.5rem !important;
}

.-mb-0_50rem {
  margin-bottom: -0.5rem !important;
}

.-mx-0_50rem {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-my-0_50rem {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.line-height-0_75rem {
  line-height: 0.75rem !important;
}

.w-0_75rem {
  width: 0.75rem !important;
}

.min-w-0_75rem {
  min-width: 0.75rem !important;
}

.max-w-0_75rem {
  max-width: 0.75rem !important;
}

.h-0_75rem {
  height: 0.75rem !important;
}

.min-h-0_75rem {
  min-height: 0.75rem !important;
}

.max-h-0_75rem {
  max-height: 0.75rem !important;
}

.gap-0_75rem {
  gap: 0.75rem !important;
}

.p-0_75rem {
  padding: 0.75rem !important;
}

.pt-0_75rem {
  padding-top: 0.75rem !important;
}

.pr-0_75rem {
  padding-right: 0.75rem !important;
}

.pl-0_75rem {
  padding-left: 0.75rem !important;
}

.pb-0_75rem {
  padding-bottom: 0.75rem !important;
}

.px-0_75rem {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-0_75rem {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.-p-0_75rem {
  padding: -0.75rem !important;
}

.-pt-0_75rem {
  padding-top: -0.75rem !important;
}

.-pr-0_75rem {
  padding-right: -0.75rem !important;
}

.-pl-0_75rem {
  padding-left: -0.75rem !important;
}

.-pb-0_75rem {
  padding-bottom: -0.75rem !important;
}

.-px-0_75rem {
  padding-left: -0.75rem !important;
  padding-right: -0.75rem !important;
}

.-py-0_75rem {
  padding-top: -0.75rem !important;
  padding-bottom: -0.75rem !important;
}

.m-0_75rem {
  margin: 0.75rem !important;
}

.mt-0_75rem {
  margin-top: 0.75rem !important;
}

.mr-0_75rem {
  margin-right: 0.75rem !important;
}

.ml-0_75rem {
  margin-left: 0.75rem !important;
}

.mb-0_75rem {
  margin-bottom: 0.75rem !important;
}

.mx-0_75rem {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.my-0_75rem {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.-m-0_75rem {
  margin: -0.75rem !important;
}

.-mt-0_75rem {
  margin-top: -0.75rem !important;
}

.-mr-0_75rem {
  margin-right: -0.75rem !important;
}

.-ml-0_75rem {
  margin-left: -0.75rem !important;
}

.-mb-0_75rem {
  margin-bottom: -0.75rem !important;
}

.-mx-0_75rem {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-my-0_75rem {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.line-height-0_125rem {
  line-height: 0.125rem !important;
}

.w-0_125rem {
  width: 0.125rem !important;
}

.min-w-0_125rem {
  min-width: 0.125rem !important;
}

.max-w-0_125rem {
  max-width: 0.125rem !important;
}

.h-0_125rem {
  height: 0.125rem !important;
}

.min-h-0_125rem {
  min-height: 0.125rem !important;
}

.max-h-0_125rem {
  max-height: 0.125rem !important;
}

.gap-0_125rem {
  gap: 0.125rem !important;
}

.p-0_125rem {
  padding: 0.125rem !important;
}

.pt-0_125rem {
  padding-top: 0.125rem !important;
}

.pr-0_125rem {
  padding-right: 0.125rem !important;
}

.pl-0_125rem {
  padding-left: 0.125rem !important;
}

.pb-0_125rem {
  padding-bottom: 0.125rem !important;
}

.px-0_125rem {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}

.py-0_125rem {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.-p-0_125rem {
  padding: -0.125rem !important;
}

.-pt-0_125rem {
  padding-top: -0.125rem !important;
}

.-pr-0_125rem {
  padding-right: -0.125rem !important;
}

.-pl-0_125rem {
  padding-left: -0.125rem !important;
}

.-pb-0_125rem {
  padding-bottom: -0.125rem !important;
}

.-px-0_125rem {
  padding-left: -0.125rem !important;
  padding-right: -0.125rem !important;
}

.-py-0_125rem {
  padding-top: -0.125rem !important;
  padding-bottom: -0.125rem !important;
}

.m-0_125rem {
  margin: 0.125rem !important;
}

.mt-0_125rem {
  margin-top: 0.125rem !important;
}

.mr-0_125rem {
  margin-right: 0.125rem !important;
}

.ml-0_125rem {
  margin-left: 0.125rem !important;
}

.mb-0_125rem {
  margin-bottom: 0.125rem !important;
}

.mx-0_125rem {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}

.my-0_125rem {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}

.-m-0_125rem {
  margin: -0.125rem !important;
}

.-mt-0_125rem {
  margin-top: -0.125rem !important;
}

.-mr-0_125rem {
  margin-right: -0.125rem !important;
}

.-ml-0_125rem {
  margin-left: -0.125rem !important;
}

.-mb-0_125rem {
  margin-bottom: -0.125rem !important;
}

.-mx-0_125rem {
  margin-left: -0.125rem !important;
  margin-right: -0.125rem !important;
}

.-my-0_125rem {
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important;
}

.line-height-0_375rem {
  line-height: 0.375rem !important;
}

.w-0_375rem {
  width: 0.375rem !important;
}

.min-w-0_375rem {
  min-width: 0.375rem !important;
}

.max-w-0_375rem {
  max-width: 0.375rem !important;
}

.h-0_375rem {
  height: 0.375rem !important;
}

.min-h-0_375rem {
  min-height: 0.375rem !important;
}

.max-h-0_375rem {
  max-height: 0.375rem !important;
}

.gap-0_375rem {
  gap: 0.375rem !important;
}

.p-0_375rem {
  padding: 0.375rem !important;
}

.pt-0_375rem {
  padding-top: 0.375rem !important;
}

.pr-0_375rem {
  padding-right: 0.375rem !important;
}

.pl-0_375rem {
  padding-left: 0.375rem !important;
}

.pb-0_375rem {
  padding-bottom: 0.375rem !important;
}

.px-0_375rem {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.py-0_375rem {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.-p-0_375rem {
  padding: -0.375rem !important;
}

.-pt-0_375rem {
  padding-top: -0.375rem !important;
}

.-pr-0_375rem {
  padding-right: -0.375rem !important;
}

.-pl-0_375rem {
  padding-left: -0.375rem !important;
}

.-pb-0_375rem {
  padding-bottom: -0.375rem !important;
}

.-px-0_375rem {
  padding-left: -0.375rem !important;
  padding-right: -0.375rem !important;
}

.-py-0_375rem {
  padding-top: -0.375rem !important;
  padding-bottom: -0.375rem !important;
}

.m-0_375rem {
  margin: 0.375rem !important;
}

.mt-0_375rem {
  margin-top: 0.375rem !important;
}

.mr-0_375rem {
  margin-right: 0.375rem !important;
}

.ml-0_375rem {
  margin-left: 0.375rem !important;
}

.mb-0_375rem {
  margin-bottom: 0.375rem !important;
}

.mx-0_375rem {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}

.my-0_375rem {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.-m-0_375rem {
  margin: -0.375rem !important;
}

.-mt-0_375rem {
  margin-top: -0.375rem !important;
}

.-mr-0_375rem {
  margin-right: -0.375rem !important;
}

.-ml-0_375rem {
  margin-left: -0.375rem !important;
}

.-mb-0_375rem {
  margin-bottom: -0.375rem !important;
}

.-mx-0_375rem {
  margin-left: -0.375rem !important;
  margin-right: -0.375rem !important;
}

.-my-0_375rem {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.line-height-0_625rem {
  line-height: 0.625rem !important;
}

.w-0_625rem {
  width: 0.625rem !important;
}

.min-w-0_625rem {
  min-width: 0.625rem !important;
}

.max-w-0_625rem {
  max-width: 0.625rem !important;
}

.h-0_625rem {
  height: 0.625rem !important;
}

.min-h-0_625rem {
  min-height: 0.625rem !important;
}

.max-h-0_625rem {
  max-height: 0.625rem !important;
}

.gap-0_625rem {
  gap: 0.625rem !important;
}

.p-0_625rem {
  padding: 0.625rem !important;
}

.pt-0_625rem {
  padding-top: 0.625rem !important;
}

.pr-0_625rem {
  padding-right: 0.625rem !important;
}

.pl-0_625rem {
  padding-left: 0.625rem !important;
}

.pb-0_625rem {
  padding-bottom: 0.625rem !important;
}

.px-0_625rem {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.py-0_625rem {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.-p-0_625rem {
  padding: -0.625rem !important;
}

.-pt-0_625rem {
  padding-top: -0.625rem !important;
}

.-pr-0_625rem {
  padding-right: -0.625rem !important;
}

.-pl-0_625rem {
  padding-left: -0.625rem !important;
}

.-pb-0_625rem {
  padding-bottom: -0.625rem !important;
}

.-px-0_625rem {
  padding-left: -0.625rem !important;
  padding-right: -0.625rem !important;
}

.-py-0_625rem {
  padding-top: -0.625rem !important;
  padding-bottom: -0.625rem !important;
}

.m-0_625rem {
  margin: 0.625rem !important;
}

.mt-0_625rem {
  margin-top: 0.625rem !important;
}

.mr-0_625rem {
  margin-right: 0.625rem !important;
}

.ml-0_625rem {
  margin-left: 0.625rem !important;
}

.mb-0_625rem {
  margin-bottom: 0.625rem !important;
}

.mx-0_625rem {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.my-0_625rem {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.-m-0_625rem {
  margin: -0.625rem !important;
}

.-mt-0_625rem {
  margin-top: -0.625rem !important;
}

.-mr-0_625rem {
  margin-right: -0.625rem !important;
}

.-ml-0_625rem {
  margin-left: -0.625rem !important;
}

.-mb-0_625rem {
  margin-bottom: -0.625rem !important;
}

.-mx-0_625rem {
  margin-left: -0.625rem !important;
  margin-right: -0.625rem !important;
}

.-my-0_625rem {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.line-height-0_875rem {
  line-height: 0.875rem !important;
}

.w-0_875rem {
  width: 0.875rem !important;
}

.min-w-0_875rem {
  min-width: 0.875rem !important;
}

.max-w-0_875rem {
  max-width: 0.875rem !important;
}

.h-0_875rem {
  height: 0.875rem !important;
}

.min-h-0_875rem {
  min-height: 0.875rem !important;
}

.max-h-0_875rem {
  max-height: 0.875rem !important;
}

.gap-0_875rem {
  gap: 0.875rem !important;
}

.p-0_875rem {
  padding: 0.875rem !important;
}

.pt-0_875rem {
  padding-top: 0.875rem !important;
}

.pr-0_875rem {
  padding-right: 0.875rem !important;
}

.pl-0_875rem {
  padding-left: 0.875rem !important;
}

.pb-0_875rem {
  padding-bottom: 0.875rem !important;
}

.px-0_875rem {
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

.py-0_875rem {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.-p-0_875rem {
  padding: -0.875rem !important;
}

.-pt-0_875rem {
  padding-top: -0.875rem !important;
}

.-pr-0_875rem {
  padding-right: -0.875rem !important;
}

.-pl-0_875rem {
  padding-left: -0.875rem !important;
}

.-pb-0_875rem {
  padding-bottom: -0.875rem !important;
}

.-px-0_875rem {
  padding-left: -0.875rem !important;
  padding-right: -0.875rem !important;
}

.-py-0_875rem {
  padding-top: -0.875rem !important;
  padding-bottom: -0.875rem !important;
}

.m-0_875rem {
  margin: 0.875rem !important;
}

.mt-0_875rem {
  margin-top: 0.875rem !important;
}

.mr-0_875rem {
  margin-right: 0.875rem !important;
}

.ml-0_875rem {
  margin-left: 0.875rem !important;
}

.mb-0_875rem {
  margin-bottom: 0.875rem !important;
}

.mx-0_875rem {
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important;
}

.my-0_875rem {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.-m-0_875rem {
  margin: -0.875rem !important;
}

.-mt-0_875rem {
  margin-top: -0.875rem !important;
}

.-mr-0_875rem {
  margin-right: -0.875rem !important;
}

.-ml-0_875rem {
  margin-left: -0.875rem !important;
}

.-mb-0_875rem {
  margin-bottom: -0.875rem !important;
}

.-mx-0_875rem {
  margin-left: -0.875rem !important;
  margin-right: -0.875rem !important;
}

.-my-0_875rem {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.line-height-1rem {
  line-height: 1rem !important;
}

.min-w-1rem {
  min-width: 1rem !important;
}

.min-h-1rem {
  min-height: 1rem !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.gap-1rem {
  gap: 1rem !important;
}

.line-height-1_25rem {
  line-height: 1.25rem !important;
}

.w-1_25rem {
  width: 1.25rem !important;
}

.min-w-1_25rem {
  min-width: 1.25rem !important;
}

.max-w-1_25rem {
  max-width: 1.25rem !important;
}

.h-1_25rem {
  height: 1.25rem !important;
}

.min-h-1_25rem {
  min-height: 1.25rem !important;
}

.max-h-1_25rem {
  max-height: 1.25rem !important;
}

.gap-1_25rem {
  gap: 1.25rem !important;
}

.p-1_25rem {
  padding: 1.25rem !important;
}

.pt-1_25rem {
  padding-top: 1.25rem !important;
}

.pr-1_25rem {
  padding-right: 1.25rem !important;
}

.pl-1_25rem {
  padding-left: 1.25rem !important;
}

.pb-1_25rem {
  padding-bottom: 1.25rem !important;
}

.px-1_25rem {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-1_25rem {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.-p-1_25rem {
  padding: -1.25rem !important;
}

.-pt-1_25rem {
  padding-top: -1.25rem !important;
}

.-pr-1_25rem {
  padding-right: -1.25rem !important;
}

.-pl-1_25rem {
  padding-left: -1.25rem !important;
}

.-pb-1_25rem {
  padding-bottom: -1.25rem !important;
}

.-px-1_25rem {
  padding-left: -1.25rem !important;
  padding-right: -1.25rem !important;
}

.-py-1_25rem {
  padding-top: -1.25rem !important;
  padding-bottom: -1.25rem !important;
}

.m-1_25rem {
  margin: 1.25rem !important;
}

.mt-1_25rem {
  margin-top: 1.25rem !important;
}

.mr-1_25rem {
  margin-right: 1.25rem !important;
}

.ml-1_25rem {
  margin-left: 1.25rem !important;
}

.mb-1_25rem {
  margin-bottom: 1.25rem !important;
}

.mx-1_25rem {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-1_25rem {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.-m-1_25rem {
  margin: -1.25rem !important;
}

.-mt-1_25rem {
  margin-top: -1.25rem !important;
}

.-mr-1_25rem {
  margin-right: -1.25rem !important;
}

.-ml-1_25rem {
  margin-left: -1.25rem !important;
}

.-mb-1_25rem {
  margin-bottom: -1.25rem !important;
}

.-mx-1_25rem {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-my-1_25rem {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.line-height-1_50rem {
  line-height: 1.5rem !important;
}

.w-1_50rem {
  width: 1.5rem !important;
}

.min-w-1_50rem {
  min-width: 1.5rem !important;
}

.max-w-1_50rem {
  max-width: 1.5rem !important;
}

.h-1_50rem {
  height: 1.5rem !important;
}

.min-h-1_50rem {
  min-height: 1.5rem !important;
}

.max-h-1_50rem {
  max-height: 1.5rem !important;
}

.gap-1_50rem {
  gap: 1.5rem !important;
}

.p-1_50rem {
  padding: 1.5rem !important;
}

.pt-1_50rem {
  padding-top: 1.5rem !important;
}

.pr-1_50rem {
  padding-right: 1.5rem !important;
}

.pl-1_50rem {
  padding-left: 1.5rem !important;
}

.pb-1_50rem {
  padding-bottom: 1.5rem !important;
}

.px-1_50rem {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-1_50rem {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.-p-1_50rem {
  padding: -1.5rem !important;
}

.-pt-1_50rem {
  padding-top: -1.5rem !important;
}

.-pr-1_50rem {
  padding-right: -1.5rem !important;
}

.-pl-1_50rem {
  padding-left: -1.5rem !important;
}

.-pb-1_50rem {
  padding-bottom: -1.5rem !important;
}

.-px-1_50rem {
  padding-left: -1.5rem !important;
  padding-right: -1.5rem !important;
}

.-py-1_50rem {
  padding-top: -1.5rem !important;
  padding-bottom: -1.5rem !important;
}

.m-1_50rem {
  margin: 1.5rem !important;
}

.mt-1_50rem {
  margin-top: 1.5rem !important;
}

.mr-1_50rem {
  margin-right: 1.5rem !important;
}

.ml-1_50rem {
  margin-left: 1.5rem !important;
}

.mb-1_50rem {
  margin-bottom: 1.5rem !important;
}

.mx-1_50rem {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-1_50rem {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.-m-1_50rem {
  margin: -1.5rem !important;
}

.-mt-1_50rem {
  margin-top: -1.5rem !important;
}

.-mr-1_50rem {
  margin-right: -1.5rem !important;
}

.-ml-1_50rem {
  margin-left: -1.5rem !important;
}

.-mb-1_50rem {
  margin-bottom: -1.5rem !important;
}

.-mx-1_50rem {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-my-1_50rem {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.line-height-1_75rem {
  line-height: 1.75rem !important;
}

.w-1_75rem {
  width: 1.75rem !important;
}

.min-w-1_75rem {
  min-width: 1.75rem !important;
}

.max-w-1_75rem {
  max-width: 1.75rem !important;
}

.h-1_75rem {
  height: 1.75rem !important;
}

.min-h-1_75rem {
  min-height: 1.75rem !important;
}

.max-h-1_75rem {
  max-height: 1.75rem !important;
}

.gap-1_75rem {
  gap: 1.75rem !important;
}

.p-1_75rem {
  padding: 1.75rem !important;
}

.pt-1_75rem {
  padding-top: 1.75rem !important;
}

.pr-1_75rem {
  padding-right: 1.75rem !important;
}

.pl-1_75rem {
  padding-left: 1.75rem !important;
}

.pb-1_75rem {
  padding-bottom: 1.75rem !important;
}

.px-1_75rem {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.py-1_75rem {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.-p-1_75rem {
  padding: -1.75rem !important;
}

.-pt-1_75rem {
  padding-top: -1.75rem !important;
}

.-pr-1_75rem {
  padding-right: -1.75rem !important;
}

.-pl-1_75rem {
  padding-left: -1.75rem !important;
}

.-pb-1_75rem {
  padding-bottom: -1.75rem !important;
}

.-px-1_75rem {
  padding-left: -1.75rem !important;
  padding-right: -1.75rem !important;
}

.-py-1_75rem {
  padding-top: -1.75rem !important;
  padding-bottom: -1.75rem !important;
}

.m-1_75rem {
  margin: 1.75rem !important;
}

.mt-1_75rem {
  margin-top: 1.75rem !important;
}

.mr-1_75rem {
  margin-right: 1.75rem !important;
}

.ml-1_75rem {
  margin-left: 1.75rem !important;
}

.mb-1_75rem {
  margin-bottom: 1.75rem !important;
}

.mx-1_75rem {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.my-1_75rem {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.-m-1_75rem {
  margin: -1.75rem !important;
}

.-mt-1_75rem {
  margin-top: -1.75rem !important;
}

.-mr-1_75rem {
  margin-right: -1.75rem !important;
}

.-ml-1_75rem {
  margin-left: -1.75rem !important;
}

.-mb-1_75rem {
  margin-bottom: -1.75rem !important;
}

.-mx-1_75rem {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.-my-1_75rem {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.line-height-1_125rem {
  line-height: 1.125rem !important;
}

.w-1_125rem {
  width: 1.125rem !important;
}

.min-w-1_125rem {
  min-width: 1.125rem !important;
}

.max-w-1_125rem {
  max-width: 1.125rem !important;
}

.h-1_125rem {
  height: 1.125rem !important;
}

.min-h-1_125rem {
  min-height: 1.125rem !important;
}

.max-h-1_125rem {
  max-height: 1.125rem !important;
}

.gap-1_125rem {
  gap: 1.125rem !important;
}

.p-1_125rem {
  padding: 1.125rem !important;
}

.pt-1_125rem {
  padding-top: 1.125rem !important;
}

.pr-1_125rem {
  padding-right: 1.125rem !important;
}

.pl-1_125rem {
  padding-left: 1.125rem !important;
}

.pb-1_125rem {
  padding-bottom: 1.125rem !important;
}

.px-1_125rem {
  padding-left: 1.125rem !important;
  padding-right: 1.125rem !important;
}

.py-1_125rem {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;
}

.-p-1_125rem {
  padding: -1.125rem !important;
}

.-pt-1_125rem {
  padding-top: -1.125rem !important;
}

.-pr-1_125rem {
  padding-right: -1.125rem !important;
}

.-pl-1_125rem {
  padding-left: -1.125rem !important;
}

.-pb-1_125rem {
  padding-bottom: -1.125rem !important;
}

.-px-1_125rem {
  padding-left: -1.125rem !important;
  padding-right: -1.125rem !important;
}

.-py-1_125rem {
  padding-top: -1.125rem !important;
  padding-bottom: -1.125rem !important;
}

.m-1_125rem {
  margin: 1.125rem !important;
}

.mt-1_125rem {
  margin-top: 1.125rem !important;
}

.mr-1_125rem {
  margin-right: 1.125rem !important;
}

.ml-1_125rem {
  margin-left: 1.125rem !important;
}

.mb-1_125rem {
  margin-bottom: 1.125rem !important;
}

.mx-1_125rem {
  margin-left: 1.125rem !important;
  margin-right: 1.125rem !important;
}

.my-1_125rem {
  margin-top: 1.125rem !important;
  margin-bottom: 1.125rem !important;
}

.-m-1_125rem {
  margin: -1.125rem !important;
}

.-mt-1_125rem {
  margin-top: -1.125rem !important;
}

.-mr-1_125rem {
  margin-right: -1.125rem !important;
}

.-ml-1_125rem {
  margin-left: -1.125rem !important;
}

.-mb-1_125rem {
  margin-bottom: -1.125rem !important;
}

.-mx-1_125rem {
  margin-left: -1.125rem !important;
  margin-right: -1.125rem !important;
}

.-my-1_125rem {
  margin-top: -1.125rem !important;
  margin-bottom: -1.125rem !important;
}

.line-height-1_375rem {
  line-height: 1.375rem !important;
}

.w-1_375rem {
  width: 1.375rem !important;
}

.min-w-1_375rem {
  min-width: 1.375rem !important;
}

.max-w-1_375rem {
  max-width: 1.375rem !important;
}

.h-1_375rem {
  height: 1.375rem !important;
}

.min-h-1_375rem {
  min-height: 1.375rem !important;
}

.max-h-1_375rem {
  max-height: 1.375rem !important;
}

.gap-1_375rem {
  gap: 1.375rem !important;
}

.p-1_375rem {
  padding: 1.375rem !important;
}

.pt-1_375rem {
  padding-top: 1.375rem !important;
}

.pr-1_375rem {
  padding-right: 1.375rem !important;
}

.pl-1_375rem {
  padding-left: 1.375rem !important;
}

.pb-1_375rem {
  padding-bottom: 1.375rem !important;
}

.px-1_375rem {
  padding-left: 1.375rem !important;
  padding-right: 1.375rem !important;
}

.py-1_375rem {
  padding-top: 1.375rem !important;
  padding-bottom: 1.375rem !important;
}

.-p-1_375rem {
  padding: -1.375rem !important;
}

.-pt-1_375rem {
  padding-top: -1.375rem !important;
}

.-pr-1_375rem {
  padding-right: -1.375rem !important;
}

.-pl-1_375rem {
  padding-left: -1.375rem !important;
}

.-pb-1_375rem {
  padding-bottom: -1.375rem !important;
}

.-px-1_375rem {
  padding-left: -1.375rem !important;
  padding-right: -1.375rem !important;
}

.-py-1_375rem {
  padding-top: -1.375rem !important;
  padding-bottom: -1.375rem !important;
}

.m-1_375rem {
  margin: 1.375rem !important;
}

.mt-1_375rem {
  margin-top: 1.375rem !important;
}

.mr-1_375rem {
  margin-right: 1.375rem !important;
}

.ml-1_375rem {
  margin-left: 1.375rem !important;
}

.mb-1_375rem {
  margin-bottom: 1.375rem !important;
}

.mx-1_375rem {
  margin-left: 1.375rem !important;
  margin-right: 1.375rem !important;
}

.my-1_375rem {
  margin-top: 1.375rem !important;
  margin-bottom: 1.375rem !important;
}

.-m-1_375rem {
  margin: -1.375rem !important;
}

.-mt-1_375rem {
  margin-top: -1.375rem !important;
}

.-mr-1_375rem {
  margin-right: -1.375rem !important;
}

.-ml-1_375rem {
  margin-left: -1.375rem !important;
}

.-mb-1_375rem {
  margin-bottom: -1.375rem !important;
}

.-mx-1_375rem {
  margin-left: -1.375rem !important;
  margin-right: -1.375rem !important;
}

.-my-1_375rem {
  margin-top: -1.375rem !important;
  margin-bottom: -1.375rem !important;
}

.line-height-1_625rem {
  line-height: 1.625rem !important;
}

.w-1_625rem {
  width: 1.625rem !important;
}

.min-w-1_625rem {
  min-width: 1.625rem !important;
}

.max-w-1_625rem {
  max-width: 1.625rem !important;
}

.h-1_625rem {
  height: 1.625rem !important;
}

.min-h-1_625rem {
  min-height: 1.625rem !important;
}

.max-h-1_625rem {
  max-height: 1.625rem !important;
}

.gap-1_625rem {
  gap: 1.625rem !important;
}

.p-1_625rem {
  padding: 1.625rem !important;
}

.pt-1_625rem {
  padding-top: 1.625rem !important;
}

.pr-1_625rem {
  padding-right: 1.625rem !important;
}

.pl-1_625rem {
  padding-left: 1.625rem !important;
}

.pb-1_625rem {
  padding-bottom: 1.625rem !important;
}

.px-1_625rem {
  padding-left: 1.625rem !important;
  padding-right: 1.625rem !important;
}

.py-1_625rem {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.-p-1_625rem {
  padding: -1.625rem !important;
}

.-pt-1_625rem {
  padding-top: -1.625rem !important;
}

.-pr-1_625rem {
  padding-right: -1.625rem !important;
}

.-pl-1_625rem {
  padding-left: -1.625rem !important;
}

.-pb-1_625rem {
  padding-bottom: -1.625rem !important;
}

.-px-1_625rem {
  padding-left: -1.625rem !important;
  padding-right: -1.625rem !important;
}

.-py-1_625rem {
  padding-top: -1.625rem !important;
  padding-bottom: -1.625rem !important;
}

.m-1_625rem {
  margin: 1.625rem !important;
}

.mt-1_625rem {
  margin-top: 1.625rem !important;
}

.mr-1_625rem {
  margin-right: 1.625rem !important;
}

.ml-1_625rem {
  margin-left: 1.625rem !important;
}

.mb-1_625rem {
  margin-bottom: 1.625rem !important;
}

.mx-1_625rem {
  margin-left: 1.625rem !important;
  margin-right: 1.625rem !important;
}

.my-1_625rem {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.-m-1_625rem {
  margin: -1.625rem !important;
}

.-mt-1_625rem {
  margin-top: -1.625rem !important;
}

.-mr-1_625rem {
  margin-right: -1.625rem !important;
}

.-ml-1_625rem {
  margin-left: -1.625rem !important;
}

.-mb-1_625rem {
  margin-bottom: -1.625rem !important;
}

.-mx-1_625rem {
  margin-left: -1.625rem !important;
  margin-right: -1.625rem !important;
}

.-my-1_625rem {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.line-height-1_875rem {
  line-height: 1.875rem !important;
}

.w-1_875rem {
  width: 1.875rem !important;
}

.min-w-1_875rem {
  min-width: 1.875rem !important;
}

.max-w-1_875rem {
  max-width: 1.875rem !important;
}

.h-1_875rem {
  height: 1.875rem !important;
}

.min-h-1_875rem {
  min-height: 1.875rem !important;
}

.max-h-1_875rem {
  max-height: 1.875rem !important;
}

.gap-1_875rem {
  gap: 1.875rem !important;
}

.p-1_875rem {
  padding: 1.875rem !important;
}

.pt-1_875rem {
  padding-top: 1.875rem !important;
}

.pr-1_875rem {
  padding-right: 1.875rem !important;
}

.pl-1_875rem {
  padding-left: 1.875rem !important;
}

.pb-1_875rem {
  padding-bottom: 1.875rem !important;
}

.px-1_875rem {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.py-1_875rem {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.-p-1_875rem {
  padding: -1.875rem !important;
}

.-pt-1_875rem {
  padding-top: -1.875rem !important;
}

.-pr-1_875rem {
  padding-right: -1.875rem !important;
}

.-pl-1_875rem {
  padding-left: -1.875rem !important;
}

.-pb-1_875rem {
  padding-bottom: -1.875rem !important;
}

.-px-1_875rem {
  padding-left: -1.875rem !important;
  padding-right: -1.875rem !important;
}

.-py-1_875rem {
  padding-top: -1.875rem !important;
  padding-bottom: -1.875rem !important;
}

.m-1_875rem {
  margin: 1.875rem !important;
}

.mt-1_875rem {
  margin-top: 1.875rem !important;
}

.mr-1_875rem {
  margin-right: 1.875rem !important;
}

.ml-1_875rem {
  margin-left: 1.875rem !important;
}

.mb-1_875rem {
  margin-bottom: 1.875rem !important;
}

.mx-1_875rem {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}

.my-1_875rem {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.-m-1_875rem {
  margin: -1.875rem !important;
}

.-mt-1_875rem {
  margin-top: -1.875rem !important;
}

.-mr-1_875rem {
  margin-right: -1.875rem !important;
}

.-ml-1_875rem {
  margin-left: -1.875rem !important;
}

.-mb-1_875rem {
  margin-bottom: -1.875rem !important;
}

.-mx-1_875rem {
  margin-left: -1.875rem !important;
  margin-right: -1.875rem !important;
}

.-my-1_875rem {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.line-height-2rem {
  line-height: 2rem !important;
}

.min-w-2rem {
  min-width: 2rem !important;
}

.min-h-2rem {
  min-height: 2rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.gap-2rem {
  gap: 2rem !important;
}

.line-height-2_25rem {
  line-height: 2.25rem !important;
}

.w-2_25rem {
  width: 2.25rem !important;
}

.min-w-2_25rem {
  min-width: 2.25rem !important;
}

.max-w-2_25rem {
  max-width: 2.25rem !important;
}

.h-2_25rem {
  height: 2.25rem !important;
}

.min-h-2_25rem {
  min-height: 2.25rem !important;
}

.max-h-2_25rem {
  max-height: 2.25rem !important;
}

.gap-2_25rem {
  gap: 2.25rem !important;
}

.p-2_25rem {
  padding: 2.25rem !important;
}

.pt-2_25rem {
  padding-top: 2.25rem !important;
}

.pr-2_25rem {
  padding-right: 2.25rem !important;
}

.pl-2_25rem {
  padding-left: 2.25rem !important;
}

.pb-2_25rem {
  padding-bottom: 2.25rem !important;
}

.px-2_25rem {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.py-2_25rem {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.-p-2_25rem {
  padding: -2.25rem !important;
}

.-pt-2_25rem {
  padding-top: -2.25rem !important;
}

.-pr-2_25rem {
  padding-right: -2.25rem !important;
}

.-pl-2_25rem {
  padding-left: -2.25rem !important;
}

.-pb-2_25rem {
  padding-bottom: -2.25rem !important;
}

.-px-2_25rem {
  padding-left: -2.25rem !important;
  padding-right: -2.25rem !important;
}

.-py-2_25rem {
  padding-top: -2.25rem !important;
  padding-bottom: -2.25rem !important;
}

.m-2_25rem {
  margin: 2.25rem !important;
}

.mt-2_25rem {
  margin-top: 2.25rem !important;
}

.mr-2_25rem {
  margin-right: 2.25rem !important;
}

.ml-2_25rem {
  margin-left: 2.25rem !important;
}

.mb-2_25rem {
  margin-bottom: 2.25rem !important;
}

.mx-2_25rem {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.my-2_25rem {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.-m-2_25rem {
  margin: -2.25rem !important;
}

.-mt-2_25rem {
  margin-top: -2.25rem !important;
}

.-mr-2_25rem {
  margin-right: -2.25rem !important;
}

.-ml-2_25rem {
  margin-left: -2.25rem !important;
}

.-mb-2_25rem {
  margin-bottom: -2.25rem !important;
}

.-mx-2_25rem {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.-my-2_25rem {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.line-height-2_50rem {
  line-height: 2.5rem !important;
}

.w-2_50rem {
  width: 2.5rem !important;
}

.min-w-2_50rem {
  min-width: 2.5rem !important;
}

.max-w-2_50rem {
  max-width: 2.5rem !important;
}

.h-2_50rem {
  height: 2.5rem !important;
}

.min-h-2_50rem {
  min-height: 2.5rem !important;
}

.max-h-2_50rem {
  max-height: 2.5rem !important;
}

.gap-2_50rem {
  gap: 2.5rem !important;
}

.p-2_50rem {
  padding: 2.5rem !important;
}

.pt-2_50rem {
  padding-top: 2.5rem !important;
}

.pr-2_50rem {
  padding-right: 2.5rem !important;
}

.pl-2_50rem {
  padding-left: 2.5rem !important;
}

.pb-2_50rem {
  padding-bottom: 2.5rem !important;
}

.px-2_50rem {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-2_50rem {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.-p-2_50rem {
  padding: -2.5rem !important;
}

.-pt-2_50rem {
  padding-top: -2.5rem !important;
}

.-pr-2_50rem {
  padding-right: -2.5rem !important;
}

.-pl-2_50rem {
  padding-left: -2.5rem !important;
}

.-pb-2_50rem {
  padding-bottom: -2.5rem !important;
}

.-px-2_50rem {
  padding-left: -2.5rem !important;
  padding-right: -2.5rem !important;
}

.-py-2_50rem {
  padding-top: -2.5rem !important;
  padding-bottom: -2.5rem !important;
}

.m-2_50rem {
  margin: 2.5rem !important;
}

.mt-2_50rem {
  margin-top: 2.5rem !important;
}

.mr-2_50rem {
  margin-right: 2.5rem !important;
}

.ml-2_50rem {
  margin-left: 2.5rem !important;
}

.mb-2_50rem {
  margin-bottom: 2.5rem !important;
}

.mx-2_50rem {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-2_50rem {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.-m-2_50rem {
  margin: -2.5rem !important;
}

.-mt-2_50rem {
  margin-top: -2.5rem !important;
}

.-mr-2_50rem {
  margin-right: -2.5rem !important;
}

.-ml-2_50rem {
  margin-left: -2.5rem !important;
}

.-mb-2_50rem {
  margin-bottom: -2.5rem !important;
}

.-mx-2_50rem {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-2_50rem {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.line-height-2_75rem {
  line-height: 2.75rem !important;
}

.w-2_75rem {
  width: 2.75rem !important;
}

.min-w-2_75rem {
  min-width: 2.75rem !important;
}

.max-w-2_75rem {
  max-width: 2.75rem !important;
}

.h-2_75rem {
  height: 2.75rem !important;
}

.min-h-2_75rem {
  min-height: 2.75rem !important;
}

.max-h-2_75rem {
  max-height: 2.75rem !important;
}

.gap-2_75rem {
  gap: 2.75rem !important;
}

.p-2_75rem {
  padding: 2.75rem !important;
}

.pt-2_75rem {
  padding-top: 2.75rem !important;
}

.pr-2_75rem {
  padding-right: 2.75rem !important;
}

.pl-2_75rem {
  padding-left: 2.75rem !important;
}

.pb-2_75rem {
  padding-bottom: 2.75rem !important;
}

.px-2_75rem {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.py-2_75rem {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.-p-2_75rem {
  padding: -2.75rem !important;
}

.-pt-2_75rem {
  padding-top: -2.75rem !important;
}

.-pr-2_75rem {
  padding-right: -2.75rem !important;
}

.-pl-2_75rem {
  padding-left: -2.75rem !important;
}

.-pb-2_75rem {
  padding-bottom: -2.75rem !important;
}

.-px-2_75rem {
  padding-left: -2.75rem !important;
  padding-right: -2.75rem !important;
}

.-py-2_75rem {
  padding-top: -2.75rem !important;
  padding-bottom: -2.75rem !important;
}

.m-2_75rem {
  margin: 2.75rem !important;
}

.mt-2_75rem {
  margin-top: 2.75rem !important;
}

.mr-2_75rem {
  margin-right: 2.75rem !important;
}

.ml-2_75rem {
  margin-left: 2.75rem !important;
}

.mb-2_75rem {
  margin-bottom: 2.75rem !important;
}

.mx-2_75rem {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.my-2_75rem {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.-m-2_75rem {
  margin: -2.75rem !important;
}

.-mt-2_75rem {
  margin-top: -2.75rem !important;
}

.-mr-2_75rem {
  margin-right: -2.75rem !important;
}

.-ml-2_75rem {
  margin-left: -2.75rem !important;
}

.-mb-2_75rem {
  margin-bottom: -2.75rem !important;
}

.-mx-2_75rem {
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.-my-2_75rem {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.line-height-2_125rem {
  line-height: 2.125rem !important;
}

.w-2_125rem {
  width: 2.125rem !important;
}

.min-w-2_125rem {
  min-width: 2.125rem !important;
}

.max-w-2_125rem {
  max-width: 2.125rem !important;
}

.h-2_125rem {
  height: 2.125rem !important;
}

.min-h-2_125rem {
  min-height: 2.125rem !important;
}

.max-h-2_125rem {
  max-height: 2.125rem !important;
}

.gap-2_125rem {
  gap: 2.125rem !important;
}

.p-2_125rem {
  padding: 2.125rem !important;
}

.pt-2_125rem {
  padding-top: 2.125rem !important;
}

.pr-2_125rem {
  padding-right: 2.125rem !important;
}

.pl-2_125rem {
  padding-left: 2.125rem !important;
}

.pb-2_125rem {
  padding-bottom: 2.125rem !important;
}

.px-2_125rem {
  padding-left: 2.125rem !important;
  padding-right: 2.125rem !important;
}

.py-2_125rem {
  padding-top: 2.125rem !important;
  padding-bottom: 2.125rem !important;
}

.-p-2_125rem {
  padding: -2.125rem !important;
}

.-pt-2_125rem {
  padding-top: -2.125rem !important;
}

.-pr-2_125rem {
  padding-right: -2.125rem !important;
}

.-pl-2_125rem {
  padding-left: -2.125rem !important;
}

.-pb-2_125rem {
  padding-bottom: -2.125rem !important;
}

.-px-2_125rem {
  padding-left: -2.125rem !important;
  padding-right: -2.125rem !important;
}

.-py-2_125rem {
  padding-top: -2.125rem !important;
  padding-bottom: -2.125rem !important;
}

.m-2_125rem {
  margin: 2.125rem !important;
}

.mt-2_125rem {
  margin-top: 2.125rem !important;
}

.mr-2_125rem {
  margin-right: 2.125rem !important;
}

.ml-2_125rem {
  margin-left: 2.125rem !important;
}

.mb-2_125rem {
  margin-bottom: 2.125rem !important;
}

.mx-2_125rem {
  margin-left: 2.125rem !important;
  margin-right: 2.125rem !important;
}

.my-2_125rem {
  margin-top: 2.125rem !important;
  margin-bottom: 2.125rem !important;
}

.-m-2_125rem {
  margin: -2.125rem !important;
}

.-mt-2_125rem {
  margin-top: -2.125rem !important;
}

.-mr-2_125rem {
  margin-right: -2.125rem !important;
}

.-ml-2_125rem {
  margin-left: -2.125rem !important;
}

.-mb-2_125rem {
  margin-bottom: -2.125rem !important;
}

.-mx-2_125rem {
  margin-left: -2.125rem !important;
  margin-right: -2.125rem !important;
}

.-my-2_125rem {
  margin-top: -2.125rem !important;
  margin-bottom: -2.125rem !important;
}

.line-height-2_375rem {
  line-height: 2.375rem !important;
}

.w-2_375rem {
  width: 2.375rem !important;
}

.min-w-2_375rem {
  min-width: 2.375rem !important;
}

.max-w-2_375rem {
  max-width: 2.375rem !important;
}

.h-2_375rem {
  height: 2.375rem !important;
}

.min-h-2_375rem {
  min-height: 2.375rem !important;
}

.max-h-2_375rem {
  max-height: 2.375rem !important;
}

.gap-2_375rem {
  gap: 2.375rem !important;
}

.p-2_375rem {
  padding: 2.375rem !important;
}

.pt-2_375rem {
  padding-top: 2.375rem !important;
}

.pr-2_375rem {
  padding-right: 2.375rem !important;
}

.pl-2_375rem {
  padding-left: 2.375rem !important;
}

.pb-2_375rem {
  padding-bottom: 2.375rem !important;
}

.px-2_375rem {
  padding-left: 2.375rem !important;
  padding-right: 2.375rem !important;
}

.py-2_375rem {
  padding-top: 2.375rem !important;
  padding-bottom: 2.375rem !important;
}

.-p-2_375rem {
  padding: -2.375rem !important;
}

.-pt-2_375rem {
  padding-top: -2.375rem !important;
}

.-pr-2_375rem {
  padding-right: -2.375rem !important;
}

.-pl-2_375rem {
  padding-left: -2.375rem !important;
}

.-pb-2_375rem {
  padding-bottom: -2.375rem !important;
}

.-px-2_375rem {
  padding-left: -2.375rem !important;
  padding-right: -2.375rem !important;
}

.-py-2_375rem {
  padding-top: -2.375rem !important;
  padding-bottom: -2.375rem !important;
}

.m-2_375rem {
  margin: 2.375rem !important;
}

.mt-2_375rem {
  margin-top: 2.375rem !important;
}

.mr-2_375rem {
  margin-right: 2.375rem !important;
}

.ml-2_375rem {
  margin-left: 2.375rem !important;
}

.mb-2_375rem {
  margin-bottom: 2.375rem !important;
}

.mx-2_375rem {
  margin-left: 2.375rem !important;
  margin-right: 2.375rem !important;
}

.my-2_375rem {
  margin-top: 2.375rem !important;
  margin-bottom: 2.375rem !important;
}

.-m-2_375rem {
  margin: -2.375rem !important;
}

.-mt-2_375rem {
  margin-top: -2.375rem !important;
}

.-mr-2_375rem {
  margin-right: -2.375rem !important;
}

.-ml-2_375rem {
  margin-left: -2.375rem !important;
}

.-mb-2_375rem {
  margin-bottom: -2.375rem !important;
}

.-mx-2_375rem {
  margin-left: -2.375rem !important;
  margin-right: -2.375rem !important;
}

.-my-2_375rem {
  margin-top: -2.375rem !important;
  margin-bottom: -2.375rem !important;
}

.line-height-2_625rem {
  line-height: 2.625rem !important;
}

.w-2_625rem {
  width: 2.625rem !important;
}

.min-w-2_625rem {
  min-width: 2.625rem !important;
}

.max-w-2_625rem {
  max-width: 2.625rem !important;
}

.h-2_625rem {
  height: 2.625rem !important;
}

.min-h-2_625rem {
  min-height: 2.625rem !important;
}

.max-h-2_625rem {
  max-height: 2.625rem !important;
}

.gap-2_625rem {
  gap: 2.625rem !important;
}

.p-2_625rem {
  padding: 2.625rem !important;
}

.pt-2_625rem {
  padding-top: 2.625rem !important;
}

.pr-2_625rem {
  padding-right: 2.625rem !important;
}

.pl-2_625rem {
  padding-left: 2.625rem !important;
}

.pb-2_625rem {
  padding-bottom: 2.625rem !important;
}

.px-2_625rem {
  padding-left: 2.625rem !important;
  padding-right: 2.625rem !important;
}

.py-2_625rem {
  padding-top: 2.625rem !important;
  padding-bottom: 2.625rem !important;
}

.-p-2_625rem {
  padding: -2.625rem !important;
}

.-pt-2_625rem {
  padding-top: -2.625rem !important;
}

.-pr-2_625rem {
  padding-right: -2.625rem !important;
}

.-pl-2_625rem {
  padding-left: -2.625rem !important;
}

.-pb-2_625rem {
  padding-bottom: -2.625rem !important;
}

.-px-2_625rem {
  padding-left: -2.625rem !important;
  padding-right: -2.625rem !important;
}

.-py-2_625rem {
  padding-top: -2.625rem !important;
  padding-bottom: -2.625rem !important;
}

.m-2_625rem {
  margin: 2.625rem !important;
}

.mt-2_625rem {
  margin-top: 2.625rem !important;
}

.mr-2_625rem {
  margin-right: 2.625rem !important;
}

.ml-2_625rem {
  margin-left: 2.625rem !important;
}

.mb-2_625rem {
  margin-bottom: 2.625rem !important;
}

.mx-2_625rem {
  margin-left: 2.625rem !important;
  margin-right: 2.625rem !important;
}

.my-2_625rem {
  margin-top: 2.625rem !important;
  margin-bottom: 2.625rem !important;
}

.-m-2_625rem {
  margin: -2.625rem !important;
}

.-mt-2_625rem {
  margin-top: -2.625rem !important;
}

.-mr-2_625rem {
  margin-right: -2.625rem !important;
}

.-ml-2_625rem {
  margin-left: -2.625rem !important;
}

.-mb-2_625rem {
  margin-bottom: -2.625rem !important;
}

.-mx-2_625rem {
  margin-left: -2.625rem !important;
  margin-right: -2.625rem !important;
}

.-my-2_625rem {
  margin-top: -2.625rem !important;
  margin-bottom: -2.625rem !important;
}

.line-height-2_875rem {
  line-height: 2.875rem !important;
}

.w-2_875rem {
  width: 2.875rem !important;
}

.min-w-2_875rem {
  min-width: 2.875rem !important;
}

.max-w-2_875rem {
  max-width: 2.875rem !important;
}

.h-2_875rem {
  height: 2.875rem !important;
}

.min-h-2_875rem {
  min-height: 2.875rem !important;
}

.max-h-2_875rem {
  max-height: 2.875rem !important;
}

.gap-2_875rem {
  gap: 2.875rem !important;
}

.p-2_875rem {
  padding: 2.875rem !important;
}

.pt-2_875rem {
  padding-top: 2.875rem !important;
}

.pr-2_875rem {
  padding-right: 2.875rem !important;
}

.pl-2_875rem {
  padding-left: 2.875rem !important;
}

.pb-2_875rem {
  padding-bottom: 2.875rem !important;
}

.px-2_875rem {
  padding-left: 2.875rem !important;
  padding-right: 2.875rem !important;
}

.py-2_875rem {
  padding-top: 2.875rem !important;
  padding-bottom: 2.875rem !important;
}

.-p-2_875rem {
  padding: -2.875rem !important;
}

.-pt-2_875rem {
  padding-top: -2.875rem !important;
}

.-pr-2_875rem {
  padding-right: -2.875rem !important;
}

.-pl-2_875rem {
  padding-left: -2.875rem !important;
}

.-pb-2_875rem {
  padding-bottom: -2.875rem !important;
}

.-px-2_875rem {
  padding-left: -2.875rem !important;
  padding-right: -2.875rem !important;
}

.-py-2_875rem {
  padding-top: -2.875rem !important;
  padding-bottom: -2.875rem !important;
}

.m-2_875rem {
  margin: 2.875rem !important;
}

.mt-2_875rem {
  margin-top: 2.875rem !important;
}

.mr-2_875rem {
  margin-right: 2.875rem !important;
}

.ml-2_875rem {
  margin-left: 2.875rem !important;
}

.mb-2_875rem {
  margin-bottom: 2.875rem !important;
}

.mx-2_875rem {
  margin-left: 2.875rem !important;
  margin-right: 2.875rem !important;
}

.my-2_875rem {
  margin-top: 2.875rem !important;
  margin-bottom: 2.875rem !important;
}

.-m-2_875rem {
  margin: -2.875rem !important;
}

.-mt-2_875rem {
  margin-top: -2.875rem !important;
}

.-mr-2_875rem {
  margin-right: -2.875rem !important;
}

.-ml-2_875rem {
  margin-left: -2.875rem !important;
}

.-mb-2_875rem {
  margin-bottom: -2.875rem !important;
}

.-mx-2_875rem {
  margin-left: -2.875rem !important;
  margin-right: -2.875rem !important;
}

.-my-2_875rem {
  margin-top: -2.875rem !important;
  margin-bottom: -2.875rem !important;
}

.line-height-3rem {
  line-height: 3rem !important;
}

.min-w-3rem {
  min-width: 3rem !important;
}

.min-h-3rem {
  min-height: 3rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.gap-3rem {
  gap: 3rem !important;
}

.line-height-3_25rem {
  line-height: 3.25rem !important;
}

.w-3_25rem {
  width: 3.25rem !important;
}

.min-w-3_25rem {
  min-width: 3.25rem !important;
}

.max-w-3_25rem {
  max-width: 3.25rem !important;
}

.h-3_25rem {
  height: 3.25rem !important;
}

.min-h-3_25rem {
  min-height: 3.25rem !important;
}

.max-h-3_25rem {
  max-height: 3.25rem !important;
}

.gap-3_25rem {
  gap: 3.25rem !important;
}

.p-3_25rem {
  padding: 3.25rem !important;
}

.pt-3_25rem {
  padding-top: 3.25rem !important;
}

.pr-3_25rem {
  padding-right: 3.25rem !important;
}

.pl-3_25rem {
  padding-left: 3.25rem !important;
}

.pb-3_25rem {
  padding-bottom: 3.25rem !important;
}

.px-3_25rem {
  padding-left: 3.25rem !important;
  padding-right: 3.25rem !important;
}

.py-3_25rem {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.-p-3_25rem {
  padding: -3.25rem !important;
}

.-pt-3_25rem {
  padding-top: -3.25rem !important;
}

.-pr-3_25rem {
  padding-right: -3.25rem !important;
}

.-pl-3_25rem {
  padding-left: -3.25rem !important;
}

.-pb-3_25rem {
  padding-bottom: -3.25rem !important;
}

.-px-3_25rem {
  padding-left: -3.25rem !important;
  padding-right: -3.25rem !important;
}

.-py-3_25rem {
  padding-top: -3.25rem !important;
  padding-bottom: -3.25rem !important;
}

.m-3_25rem {
  margin: 3.25rem !important;
}

.mt-3_25rem {
  margin-top: 3.25rem !important;
}

.mr-3_25rem {
  margin-right: 3.25rem !important;
}

.ml-3_25rem {
  margin-left: 3.25rem !important;
}

.mb-3_25rem {
  margin-bottom: 3.25rem !important;
}

.mx-3_25rem {
  margin-left: 3.25rem !important;
  margin-right: 3.25rem !important;
}

.my-3_25rem {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.-m-3_25rem {
  margin: -3.25rem !important;
}

.-mt-3_25rem {
  margin-top: -3.25rem !important;
}

.-mr-3_25rem {
  margin-right: -3.25rem !important;
}

.-ml-3_25rem {
  margin-left: -3.25rem !important;
}

.-mb-3_25rem {
  margin-bottom: -3.25rem !important;
}

.-mx-3_25rem {
  margin-left: -3.25rem !important;
  margin-right: -3.25rem !important;
}

.-my-3_25rem {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.line-height-3_50rem {
  line-height: 3.5rem !important;
}

.w-3_50rem {
  width: 3.5rem !important;
}

.min-w-3_50rem {
  min-width: 3.5rem !important;
}

.max-w-3_50rem {
  max-width: 3.5rem !important;
}

.h-3_50rem {
  height: 3.5rem !important;
}

.min-h-3_50rem {
  min-height: 3.5rem !important;
}

.max-h-3_50rem {
  max-height: 3.5rem !important;
}

.gap-3_50rem {
  gap: 3.5rem !important;
}

.p-3_50rem {
  padding: 3.5rem !important;
}

.pt-3_50rem {
  padding-top: 3.5rem !important;
}

.pr-3_50rem {
  padding-right: 3.5rem !important;
}

.pl-3_50rem {
  padding-left: 3.5rem !important;
}

.pb-3_50rem {
  padding-bottom: 3.5rem !important;
}

.px-3_50rem {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.py-3_50rem {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.-p-3_50rem {
  padding: -3.5rem !important;
}

.-pt-3_50rem {
  padding-top: -3.5rem !important;
}

.-pr-3_50rem {
  padding-right: -3.5rem !important;
}

.-pl-3_50rem {
  padding-left: -3.5rem !important;
}

.-pb-3_50rem {
  padding-bottom: -3.5rem !important;
}

.-px-3_50rem {
  padding-left: -3.5rem !important;
  padding-right: -3.5rem !important;
}

.-py-3_50rem {
  padding-top: -3.5rem !important;
  padding-bottom: -3.5rem !important;
}

.m-3_50rem {
  margin: 3.5rem !important;
}

.mt-3_50rem {
  margin-top: 3.5rem !important;
}

.mr-3_50rem {
  margin-right: 3.5rem !important;
}

.ml-3_50rem {
  margin-left: 3.5rem !important;
}

.mb-3_50rem {
  margin-bottom: 3.5rem !important;
}

.mx-3_50rem {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.my-3_50rem {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.-m-3_50rem {
  margin: -3.5rem !important;
}

.-mt-3_50rem {
  margin-top: -3.5rem !important;
}

.-mr-3_50rem {
  margin-right: -3.5rem !important;
}

.-ml-3_50rem {
  margin-left: -3.5rem !important;
}

.-mb-3_50rem {
  margin-bottom: -3.5rem !important;
}

.-mx-3_50rem {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.-my-3_50rem {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.line-height-3_75rem {
  line-height: 3.75rem !important;
}

.w-3_75rem {
  width: 3.75rem !important;
}

.min-w-3_75rem {
  min-width: 3.75rem !important;
}

.max-w-3_75rem {
  max-width: 3.75rem !important;
}

.h-3_75rem {
  height: 3.75rem !important;
}

.min-h-3_75rem {
  min-height: 3.75rem !important;
}

.max-h-3_75rem {
  max-height: 3.75rem !important;
}

.gap-3_75rem {
  gap: 3.75rem !important;
}

.p-3_75rem {
  padding: 3.75rem !important;
}

.pt-3_75rem {
  padding-top: 3.75rem !important;
}

.pr-3_75rem {
  padding-right: 3.75rem !important;
}

.pl-3_75rem {
  padding-left: 3.75rem !important;
}

.pb-3_75rem {
  padding-bottom: 3.75rem !important;
}

.px-3_75rem {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.py-3_75rem {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.-p-3_75rem {
  padding: -3.75rem !important;
}

.-pt-3_75rem {
  padding-top: -3.75rem !important;
}

.-pr-3_75rem {
  padding-right: -3.75rem !important;
}

.-pl-3_75rem {
  padding-left: -3.75rem !important;
}

.-pb-3_75rem {
  padding-bottom: -3.75rem !important;
}

.-px-3_75rem {
  padding-left: -3.75rem !important;
  padding-right: -3.75rem !important;
}

.-py-3_75rem {
  padding-top: -3.75rem !important;
  padding-bottom: -3.75rem !important;
}

.m-3_75rem {
  margin: 3.75rem !important;
}

.mt-3_75rem {
  margin-top: 3.75rem !important;
}

.mr-3_75rem {
  margin-right: 3.75rem !important;
}

.ml-3_75rem {
  margin-left: 3.75rem !important;
}

.mb-3_75rem {
  margin-bottom: 3.75rem !important;
}

.mx-3_75rem {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
}

.my-3_75rem {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.-m-3_75rem {
  margin: -3.75rem !important;
}

.-mt-3_75rem {
  margin-top: -3.75rem !important;
}

.-mr-3_75rem {
  margin-right: -3.75rem !important;
}

.-ml-3_75rem {
  margin-left: -3.75rem !important;
}

.-mb-3_75rem {
  margin-bottom: -3.75rem !important;
}

.-mx-3_75rem {
  margin-left: -3.75rem !important;
  margin-right: -3.75rem !important;
}

.-my-3_75rem {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.line-height-3_125rem {
  line-height: 3.125rem !important;
}

.w-3_125rem {
  width: 3.125rem !important;
}

.min-w-3_125rem {
  min-width: 3.125rem !important;
}

.max-w-3_125rem {
  max-width: 3.125rem !important;
}

.h-3_125rem {
  height: 3.125rem !important;
}

.min-h-3_125rem {
  min-height: 3.125rem !important;
}

.max-h-3_125rem {
  max-height: 3.125rem !important;
}

.gap-3_125rem {
  gap: 3.125rem !important;
}

.p-3_125rem {
  padding: 3.125rem !important;
}

.pt-3_125rem {
  padding-top: 3.125rem !important;
}

.pr-3_125rem {
  padding-right: 3.125rem !important;
}

.pl-3_125rem {
  padding-left: 3.125rem !important;
}

.pb-3_125rem {
  padding-bottom: 3.125rem !important;
}

.px-3_125rem {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

.py-3_125rem {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.-p-3_125rem {
  padding: -3.125rem !important;
}

.-pt-3_125rem {
  padding-top: -3.125rem !important;
}

.-pr-3_125rem {
  padding-right: -3.125rem !important;
}

.-pl-3_125rem {
  padding-left: -3.125rem !important;
}

.-pb-3_125rem {
  padding-bottom: -3.125rem !important;
}

.-px-3_125rem {
  padding-left: -3.125rem !important;
  padding-right: -3.125rem !important;
}

.-py-3_125rem {
  padding-top: -3.125rem !important;
  padding-bottom: -3.125rem !important;
}

.m-3_125rem {
  margin: 3.125rem !important;
}

.mt-3_125rem {
  margin-top: 3.125rem !important;
}

.mr-3_125rem {
  margin-right: 3.125rem !important;
}

.ml-3_125rem {
  margin-left: 3.125rem !important;
}

.mb-3_125rem {
  margin-bottom: 3.125rem !important;
}

.mx-3_125rem {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important;
}

.my-3_125rem {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.-m-3_125rem {
  margin: -3.125rem !important;
}

.-mt-3_125rem {
  margin-top: -3.125rem !important;
}

.-mr-3_125rem {
  margin-right: -3.125rem !important;
}

.-ml-3_125rem {
  margin-left: -3.125rem !important;
}

.-mb-3_125rem {
  margin-bottom: -3.125rem !important;
}

.-mx-3_125rem {
  margin-left: -3.125rem !important;
  margin-right: -3.125rem !important;
}

.-my-3_125rem {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.line-height-3_375rem {
  line-height: 3.375rem !important;
}

.w-3_375rem {
  width: 3.375rem !important;
}

.min-w-3_375rem {
  min-width: 3.375rem !important;
}

.max-w-3_375rem {
  max-width: 3.375rem !important;
}

.h-3_375rem {
  height: 3.375rem !important;
}

.min-h-3_375rem {
  min-height: 3.375rem !important;
}

.max-h-3_375rem {
  max-height: 3.375rem !important;
}

.gap-3_375rem {
  gap: 3.375rem !important;
}

.p-3_375rem {
  padding: 3.375rem !important;
}

.pt-3_375rem {
  padding-top: 3.375rem !important;
}

.pr-3_375rem {
  padding-right: 3.375rem !important;
}

.pl-3_375rem {
  padding-left: 3.375rem !important;
}

.pb-3_375rem {
  padding-bottom: 3.375rem !important;
}

.px-3_375rem {
  padding-left: 3.375rem !important;
  padding-right: 3.375rem !important;
}

.py-3_375rem {
  padding-top: 3.375rem !important;
  padding-bottom: 3.375rem !important;
}

.-p-3_375rem {
  padding: -3.375rem !important;
}

.-pt-3_375rem {
  padding-top: -3.375rem !important;
}

.-pr-3_375rem {
  padding-right: -3.375rem !important;
}

.-pl-3_375rem {
  padding-left: -3.375rem !important;
}

.-pb-3_375rem {
  padding-bottom: -3.375rem !important;
}

.-px-3_375rem {
  padding-left: -3.375rem !important;
  padding-right: -3.375rem !important;
}

.-py-3_375rem {
  padding-top: -3.375rem !important;
  padding-bottom: -3.375rem !important;
}

.m-3_375rem {
  margin: 3.375rem !important;
}

.mt-3_375rem {
  margin-top: 3.375rem !important;
}

.mr-3_375rem {
  margin-right: 3.375rem !important;
}

.ml-3_375rem {
  margin-left: 3.375rem !important;
}

.mb-3_375rem {
  margin-bottom: 3.375rem !important;
}

.mx-3_375rem {
  margin-left: 3.375rem !important;
  margin-right: 3.375rem !important;
}

.my-3_375rem {
  margin-top: 3.375rem !important;
  margin-bottom: 3.375rem !important;
}

.-m-3_375rem {
  margin: -3.375rem !important;
}

.-mt-3_375rem {
  margin-top: -3.375rem !important;
}

.-mr-3_375rem {
  margin-right: -3.375rem !important;
}

.-ml-3_375rem {
  margin-left: -3.375rem !important;
}

.-mb-3_375rem {
  margin-bottom: -3.375rem !important;
}

.-mx-3_375rem {
  margin-left: -3.375rem !important;
  margin-right: -3.375rem !important;
}

.-my-3_375rem {
  margin-top: -3.375rem !important;
  margin-bottom: -3.375rem !important;
}

.line-height-3_625rem {
  line-height: 3.625rem !important;
}

.w-3_625rem {
  width: 3.625rem !important;
}

.min-w-3_625rem {
  min-width: 3.625rem !important;
}

.max-w-3_625rem {
  max-width: 3.625rem !important;
}

.h-3_625rem {
  height: 3.625rem !important;
}

.min-h-3_625rem {
  min-height: 3.625rem !important;
}

.max-h-3_625rem {
  max-height: 3.625rem !important;
}

.gap-3_625rem {
  gap: 3.625rem !important;
}

.p-3_625rem {
  padding: 3.625rem !important;
}

.pt-3_625rem {
  padding-top: 3.625rem !important;
}

.pr-3_625rem {
  padding-right: 3.625rem !important;
}

.pl-3_625rem {
  padding-left: 3.625rem !important;
}

.pb-3_625rem {
  padding-bottom: 3.625rem !important;
}

.px-3_625rem {
  padding-left: 3.625rem !important;
  padding-right: 3.625rem !important;
}

.py-3_625rem {
  padding-top: 3.625rem !important;
  padding-bottom: 3.625rem !important;
}

.-p-3_625rem {
  padding: -3.625rem !important;
}

.-pt-3_625rem {
  padding-top: -3.625rem !important;
}

.-pr-3_625rem {
  padding-right: -3.625rem !important;
}

.-pl-3_625rem {
  padding-left: -3.625rem !important;
}

.-pb-3_625rem {
  padding-bottom: -3.625rem !important;
}

.-px-3_625rem {
  padding-left: -3.625rem !important;
  padding-right: -3.625rem !important;
}

.-py-3_625rem {
  padding-top: -3.625rem !important;
  padding-bottom: -3.625rem !important;
}

.m-3_625rem {
  margin: 3.625rem !important;
}

.mt-3_625rem {
  margin-top: 3.625rem !important;
}

.mr-3_625rem {
  margin-right: 3.625rem !important;
}

.ml-3_625rem {
  margin-left: 3.625rem !important;
}

.mb-3_625rem {
  margin-bottom: 3.625rem !important;
}

.mx-3_625rem {
  margin-left: 3.625rem !important;
  margin-right: 3.625rem !important;
}

.my-3_625rem {
  margin-top: 3.625rem !important;
  margin-bottom: 3.625rem !important;
}

.-m-3_625rem {
  margin: -3.625rem !important;
}

.-mt-3_625rem {
  margin-top: -3.625rem !important;
}

.-mr-3_625rem {
  margin-right: -3.625rem !important;
}

.-ml-3_625rem {
  margin-left: -3.625rem !important;
}

.-mb-3_625rem {
  margin-bottom: -3.625rem !important;
}

.-mx-3_625rem {
  margin-left: -3.625rem !important;
  margin-right: -3.625rem !important;
}

.-my-3_625rem {
  margin-top: -3.625rem !important;
  margin-bottom: -3.625rem !important;
}

.line-height-3_875rem {
  line-height: 3.875rem !important;
}

.w-3_875rem {
  width: 3.875rem !important;
}

.min-w-3_875rem {
  min-width: 3.875rem !important;
}

.max-w-3_875rem {
  max-width: 3.875rem !important;
}

.h-3_875rem {
  height: 3.875rem !important;
}

.min-h-3_875rem {
  min-height: 3.875rem !important;
}

.max-h-3_875rem {
  max-height: 3.875rem !important;
}

.gap-3_875rem {
  gap: 3.875rem !important;
}

.p-3_875rem {
  padding: 3.875rem !important;
}

.pt-3_875rem {
  padding-top: 3.875rem !important;
}

.pr-3_875rem {
  padding-right: 3.875rem !important;
}

.pl-3_875rem {
  padding-left: 3.875rem !important;
}

.pb-3_875rem {
  padding-bottom: 3.875rem !important;
}

.px-3_875rem {
  padding-left: 3.875rem !important;
  padding-right: 3.875rem !important;
}

.py-3_875rem {
  padding-top: 3.875rem !important;
  padding-bottom: 3.875rem !important;
}

.-p-3_875rem {
  padding: -3.875rem !important;
}

.-pt-3_875rem {
  padding-top: -3.875rem !important;
}

.-pr-3_875rem {
  padding-right: -3.875rem !important;
}

.-pl-3_875rem {
  padding-left: -3.875rem !important;
}

.-pb-3_875rem {
  padding-bottom: -3.875rem !important;
}

.-px-3_875rem {
  padding-left: -3.875rem !important;
  padding-right: -3.875rem !important;
}

.-py-3_875rem {
  padding-top: -3.875rem !important;
  padding-bottom: -3.875rem !important;
}

.m-3_875rem {
  margin: 3.875rem !important;
}

.mt-3_875rem {
  margin-top: 3.875rem !important;
}

.mr-3_875rem {
  margin-right: 3.875rem !important;
}

.ml-3_875rem {
  margin-left: 3.875rem !important;
}

.mb-3_875rem {
  margin-bottom: 3.875rem !important;
}

.mx-3_875rem {
  margin-left: 3.875rem !important;
  margin-right: 3.875rem !important;
}

.my-3_875rem {
  margin-top: 3.875rem !important;
  margin-bottom: 3.875rem !important;
}

.-m-3_875rem {
  margin: -3.875rem !important;
}

.-mt-3_875rem {
  margin-top: -3.875rem !important;
}

.-mr-3_875rem {
  margin-right: -3.875rem !important;
}

.-ml-3_875rem {
  margin-left: -3.875rem !important;
}

.-mb-3_875rem {
  margin-bottom: -3.875rem !important;
}

.-mx-3_875rem {
  margin-left: -3.875rem !important;
  margin-right: -3.875rem !important;
}

.-my-3_875rem {
  margin-top: -3.875rem !important;
  margin-bottom: -3.875rem !important;
}

.line-height-4rem {
  line-height: 4rem !important;
}

.min-w-4rem {
  min-width: 4rem !important;
}

.min-h-4rem {
  min-height: 4rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.gap-4rem {
  gap: 4rem !important;
}

.line-height-4_25rem {
  line-height: 4.25rem !important;
}

.w-4_25rem {
  width: 4.25rem !important;
}

.min-w-4_25rem {
  min-width: 4.25rem !important;
}

.max-w-4_25rem {
  max-width: 4.25rem !important;
}

.h-4_25rem {
  height: 4.25rem !important;
}

.min-h-4_25rem {
  min-height: 4.25rem !important;
}

.max-h-4_25rem {
  max-height: 4.25rem !important;
}

.gap-4_25rem {
  gap: 4.25rem !important;
}

.p-4_25rem {
  padding: 4.25rem !important;
}

.pt-4_25rem {
  padding-top: 4.25rem !important;
}

.pr-4_25rem {
  padding-right: 4.25rem !important;
}

.pl-4_25rem {
  padding-left: 4.25rem !important;
}

.pb-4_25rem {
  padding-bottom: 4.25rem !important;
}

.px-4_25rem {
  padding-left: 4.25rem !important;
  padding-right: 4.25rem !important;
}

.py-4_25rem {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.-p-4_25rem {
  padding: -4.25rem !important;
}

.-pt-4_25rem {
  padding-top: -4.25rem !important;
}

.-pr-4_25rem {
  padding-right: -4.25rem !important;
}

.-pl-4_25rem {
  padding-left: -4.25rem !important;
}

.-pb-4_25rem {
  padding-bottom: -4.25rem !important;
}

.-px-4_25rem {
  padding-left: -4.25rem !important;
  padding-right: -4.25rem !important;
}

.-py-4_25rem {
  padding-top: -4.25rem !important;
  padding-bottom: -4.25rem !important;
}

.m-4_25rem {
  margin: 4.25rem !important;
}

.mt-4_25rem {
  margin-top: 4.25rem !important;
}

.mr-4_25rem {
  margin-right: 4.25rem !important;
}

.ml-4_25rem {
  margin-left: 4.25rem !important;
}

.mb-4_25rem {
  margin-bottom: 4.25rem !important;
}

.mx-4_25rem {
  margin-left: 4.25rem !important;
  margin-right: 4.25rem !important;
}

.my-4_25rem {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.-m-4_25rem {
  margin: -4.25rem !important;
}

.-mt-4_25rem {
  margin-top: -4.25rem !important;
}

.-mr-4_25rem {
  margin-right: -4.25rem !important;
}

.-ml-4_25rem {
  margin-left: -4.25rem !important;
}

.-mb-4_25rem {
  margin-bottom: -4.25rem !important;
}

.-mx-4_25rem {
  margin-left: -4.25rem !important;
  margin-right: -4.25rem !important;
}

.-my-4_25rem {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.line-height-4_50rem {
  line-height: 4.5rem !important;
}

.w-4_50rem {
  width: 4.5rem !important;
}

.min-w-4_50rem {
  min-width: 4.5rem !important;
}

.max-w-4_50rem {
  max-width: 4.5rem !important;
}

.h-4_50rem {
  height: 4.5rem !important;
}

.min-h-4_50rem {
  min-height: 4.5rem !important;
}

.max-h-4_50rem {
  max-height: 4.5rem !important;
}

.gap-4_50rem {
  gap: 4.5rem !important;
}

.p-4_50rem {
  padding: 4.5rem !important;
}

.pt-4_50rem {
  padding-top: 4.5rem !important;
}

.pr-4_50rem {
  padding-right: 4.5rem !important;
}

.pl-4_50rem {
  padding-left: 4.5rem !important;
}

.pb-4_50rem {
  padding-bottom: 4.5rem !important;
}

.px-4_50rem {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.py-4_50rem {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.-p-4_50rem {
  padding: -4.5rem !important;
}

.-pt-4_50rem {
  padding-top: -4.5rem !important;
}

.-pr-4_50rem {
  padding-right: -4.5rem !important;
}

.-pl-4_50rem {
  padding-left: -4.5rem !important;
}

.-pb-4_50rem {
  padding-bottom: -4.5rem !important;
}

.-px-4_50rem {
  padding-left: -4.5rem !important;
  padding-right: -4.5rem !important;
}

.-py-4_50rem {
  padding-top: -4.5rem !important;
  padding-bottom: -4.5rem !important;
}

.m-4_50rem {
  margin: 4.5rem !important;
}

.mt-4_50rem {
  margin-top: 4.5rem !important;
}

.mr-4_50rem {
  margin-right: 4.5rem !important;
}

.ml-4_50rem {
  margin-left: 4.5rem !important;
}

.mb-4_50rem {
  margin-bottom: 4.5rem !important;
}

.mx-4_50rem {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.my-4_50rem {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.-m-4_50rem {
  margin: -4.5rem !important;
}

.-mt-4_50rem {
  margin-top: -4.5rem !important;
}

.-mr-4_50rem {
  margin-right: -4.5rem !important;
}

.-ml-4_50rem {
  margin-left: -4.5rem !important;
}

.-mb-4_50rem {
  margin-bottom: -4.5rem !important;
}

.-mx-4_50rem {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.-my-4_50rem {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.line-height-4_75rem {
  line-height: 4.75rem !important;
}

.w-4_75rem {
  width: 4.75rem !important;
}

.min-w-4_75rem {
  min-width: 4.75rem !important;
}

.max-w-4_75rem {
  max-width: 4.75rem !important;
}

.h-4_75rem {
  height: 4.75rem !important;
}

.min-h-4_75rem {
  min-height: 4.75rem !important;
}

.max-h-4_75rem {
  max-height: 4.75rem !important;
}

.gap-4_75rem {
  gap: 4.75rem !important;
}

.p-4_75rem {
  padding: 4.75rem !important;
}

.pt-4_75rem {
  padding-top: 4.75rem !important;
}

.pr-4_75rem {
  padding-right: 4.75rem !important;
}

.pl-4_75rem {
  padding-left: 4.75rem !important;
}

.pb-4_75rem {
  padding-bottom: 4.75rem !important;
}

.px-4_75rem {
  padding-left: 4.75rem !important;
  padding-right: 4.75rem !important;
}

.py-4_75rem {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.-p-4_75rem {
  padding: -4.75rem !important;
}

.-pt-4_75rem {
  padding-top: -4.75rem !important;
}

.-pr-4_75rem {
  padding-right: -4.75rem !important;
}

.-pl-4_75rem {
  padding-left: -4.75rem !important;
}

.-pb-4_75rem {
  padding-bottom: -4.75rem !important;
}

.-px-4_75rem {
  padding-left: -4.75rem !important;
  padding-right: -4.75rem !important;
}

.-py-4_75rem {
  padding-top: -4.75rem !important;
  padding-bottom: -4.75rem !important;
}

.m-4_75rem {
  margin: 4.75rem !important;
}

.mt-4_75rem {
  margin-top: 4.75rem !important;
}

.mr-4_75rem {
  margin-right: 4.75rem !important;
}

.ml-4_75rem {
  margin-left: 4.75rem !important;
}

.mb-4_75rem {
  margin-bottom: 4.75rem !important;
}

.mx-4_75rem {
  margin-left: 4.75rem !important;
  margin-right: 4.75rem !important;
}

.my-4_75rem {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.-m-4_75rem {
  margin: -4.75rem !important;
}

.-mt-4_75rem {
  margin-top: -4.75rem !important;
}

.-mr-4_75rem {
  margin-right: -4.75rem !important;
}

.-ml-4_75rem {
  margin-left: -4.75rem !important;
}

.-mb-4_75rem {
  margin-bottom: -4.75rem !important;
}

.-mx-4_75rem {
  margin-left: -4.75rem !important;
  margin-right: -4.75rem !important;
}

.-my-4_75rem {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.line-height-4_125rem {
  line-height: 4.125rem !important;
}

.w-4_125rem {
  width: 4.125rem !important;
}

.min-w-4_125rem {
  min-width: 4.125rem !important;
}

.max-w-4_125rem {
  max-width: 4.125rem !important;
}

.h-4_125rem {
  height: 4.125rem !important;
}

.min-h-4_125rem {
  min-height: 4.125rem !important;
}

.max-h-4_125rem {
  max-height: 4.125rem !important;
}

.gap-4_125rem {
  gap: 4.125rem !important;
}

.p-4_125rem {
  padding: 4.125rem !important;
}

.pt-4_125rem {
  padding-top: 4.125rem !important;
}

.pr-4_125rem {
  padding-right: 4.125rem !important;
}

.pl-4_125rem {
  padding-left: 4.125rem !important;
}

.pb-4_125rem {
  padding-bottom: 4.125rem !important;
}

.px-4_125rem {
  padding-left: 4.125rem !important;
  padding-right: 4.125rem !important;
}

.py-4_125rem {
  padding-top: 4.125rem !important;
  padding-bottom: 4.125rem !important;
}

.-p-4_125rem {
  padding: -4.125rem !important;
}

.-pt-4_125rem {
  padding-top: -4.125rem !important;
}

.-pr-4_125rem {
  padding-right: -4.125rem !important;
}

.-pl-4_125rem {
  padding-left: -4.125rem !important;
}

.-pb-4_125rem {
  padding-bottom: -4.125rem !important;
}

.-px-4_125rem {
  padding-left: -4.125rem !important;
  padding-right: -4.125rem !important;
}

.-py-4_125rem {
  padding-top: -4.125rem !important;
  padding-bottom: -4.125rem !important;
}

.m-4_125rem {
  margin: 4.125rem !important;
}

.mt-4_125rem {
  margin-top: 4.125rem !important;
}

.mr-4_125rem {
  margin-right: 4.125rem !important;
}

.ml-4_125rem {
  margin-left: 4.125rem !important;
}

.mb-4_125rem {
  margin-bottom: 4.125rem !important;
}

.mx-4_125rem {
  margin-left: 4.125rem !important;
  margin-right: 4.125rem !important;
}

.my-4_125rem {
  margin-top: 4.125rem !important;
  margin-bottom: 4.125rem !important;
}

.-m-4_125rem {
  margin: -4.125rem !important;
}

.-mt-4_125rem {
  margin-top: -4.125rem !important;
}

.-mr-4_125rem {
  margin-right: -4.125rem !important;
}

.-ml-4_125rem {
  margin-left: -4.125rem !important;
}

.-mb-4_125rem {
  margin-bottom: -4.125rem !important;
}

.-mx-4_125rem {
  margin-left: -4.125rem !important;
  margin-right: -4.125rem !important;
}

.-my-4_125rem {
  margin-top: -4.125rem !important;
  margin-bottom: -4.125rem !important;
}

.line-height-4_375rem {
  line-height: 4.375rem !important;
}

.w-4_375rem {
  width: 4.375rem !important;
}

.min-w-4_375rem {
  min-width: 4.375rem !important;
}

.max-w-4_375rem {
  max-width: 4.375rem !important;
}

.h-4_375rem {
  height: 4.375rem !important;
}

.min-h-4_375rem {
  min-height: 4.375rem !important;
}

.max-h-4_375rem {
  max-height: 4.375rem !important;
}

.gap-4_375rem {
  gap: 4.375rem !important;
}

.p-4_375rem {
  padding: 4.375rem !important;
}

.pt-4_375rem {
  padding-top: 4.375rem !important;
}

.pr-4_375rem {
  padding-right: 4.375rem !important;
}

.pl-4_375rem {
  padding-left: 4.375rem !important;
}

.pb-4_375rem {
  padding-bottom: 4.375rem !important;
}

.px-4_375rem {
  padding-left: 4.375rem !important;
  padding-right: 4.375rem !important;
}

.py-4_375rem {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.-p-4_375rem {
  padding: -4.375rem !important;
}

.-pt-4_375rem {
  padding-top: -4.375rem !important;
}

.-pr-4_375rem {
  padding-right: -4.375rem !important;
}

.-pl-4_375rem {
  padding-left: -4.375rem !important;
}

.-pb-4_375rem {
  padding-bottom: -4.375rem !important;
}

.-px-4_375rem {
  padding-left: -4.375rem !important;
  padding-right: -4.375rem !important;
}

.-py-4_375rem {
  padding-top: -4.375rem !important;
  padding-bottom: -4.375rem !important;
}

.m-4_375rem {
  margin: 4.375rem !important;
}

.mt-4_375rem {
  margin-top: 4.375rem !important;
}

.mr-4_375rem {
  margin-right: 4.375rem !important;
}

.ml-4_375rem {
  margin-left: 4.375rem !important;
}

.mb-4_375rem {
  margin-bottom: 4.375rem !important;
}

.mx-4_375rem {
  margin-left: 4.375rem !important;
  margin-right: 4.375rem !important;
}

.my-4_375rem {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.-m-4_375rem {
  margin: -4.375rem !important;
}

.-mt-4_375rem {
  margin-top: -4.375rem !important;
}

.-mr-4_375rem {
  margin-right: -4.375rem !important;
}

.-ml-4_375rem {
  margin-left: -4.375rem !important;
}

.-mb-4_375rem {
  margin-bottom: -4.375rem !important;
}

.-mx-4_375rem {
  margin-left: -4.375rem !important;
  margin-right: -4.375rem !important;
}

.-my-4_375rem {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important;
}

.line-height-4_625rem {
  line-height: 4.625rem !important;
}

.w-4_625rem {
  width: 4.625rem !important;
}

.min-w-4_625rem {
  min-width: 4.625rem !important;
}

.max-w-4_625rem {
  max-width: 4.625rem !important;
}

.h-4_625rem {
  height: 4.625rem !important;
}

.min-h-4_625rem {
  min-height: 4.625rem !important;
}

.max-h-4_625rem {
  max-height: 4.625rem !important;
}

.gap-4_625rem {
  gap: 4.625rem !important;
}

.p-4_625rem {
  padding: 4.625rem !important;
}

.pt-4_625rem {
  padding-top: 4.625rem !important;
}

.pr-4_625rem {
  padding-right: 4.625rem !important;
}

.pl-4_625rem {
  padding-left: 4.625rem !important;
}

.pb-4_625rem {
  padding-bottom: 4.625rem !important;
}

.px-4_625rem {
  padding-left: 4.625rem !important;
  padding-right: 4.625rem !important;
}

.py-4_625rem {
  padding-top: 4.625rem !important;
  padding-bottom: 4.625rem !important;
}

.-p-4_625rem {
  padding: -4.625rem !important;
}

.-pt-4_625rem {
  padding-top: -4.625rem !important;
}

.-pr-4_625rem {
  padding-right: -4.625rem !important;
}

.-pl-4_625rem {
  padding-left: -4.625rem !important;
}

.-pb-4_625rem {
  padding-bottom: -4.625rem !important;
}

.-px-4_625rem {
  padding-left: -4.625rem !important;
  padding-right: -4.625rem !important;
}

.-py-4_625rem {
  padding-top: -4.625rem !important;
  padding-bottom: -4.625rem !important;
}

.m-4_625rem {
  margin: 4.625rem !important;
}

.mt-4_625rem {
  margin-top: 4.625rem !important;
}

.mr-4_625rem {
  margin-right: 4.625rem !important;
}

.ml-4_625rem {
  margin-left: 4.625rem !important;
}

.mb-4_625rem {
  margin-bottom: 4.625rem !important;
}

.mx-4_625rem {
  margin-left: 4.625rem !important;
  margin-right: 4.625rem !important;
}

.my-4_625rem {
  margin-top: 4.625rem !important;
  margin-bottom: 4.625rem !important;
}

.-m-4_625rem {
  margin: -4.625rem !important;
}

.-mt-4_625rem {
  margin-top: -4.625rem !important;
}

.-mr-4_625rem {
  margin-right: -4.625rem !important;
}

.-ml-4_625rem {
  margin-left: -4.625rem !important;
}

.-mb-4_625rem {
  margin-bottom: -4.625rem !important;
}

.-mx-4_625rem {
  margin-left: -4.625rem !important;
  margin-right: -4.625rem !important;
}

.-my-4_625rem {
  margin-top: -4.625rem !important;
  margin-bottom: -4.625rem !important;
}

.line-height-4_875rem {
  line-height: 4.875rem !important;
}

.w-4_875rem {
  width: 4.875rem !important;
}

.min-w-4_875rem {
  min-width: 4.875rem !important;
}

.max-w-4_875rem {
  max-width: 4.875rem !important;
}

.h-4_875rem {
  height: 4.875rem !important;
}

.min-h-4_875rem {
  min-height: 4.875rem !important;
}

.max-h-4_875rem {
  max-height: 4.875rem !important;
}

.gap-4_875rem {
  gap: 4.875rem !important;
}

.p-4_875rem {
  padding: 4.875rem !important;
}

.pt-4_875rem {
  padding-top: 4.875rem !important;
}

.pr-4_875rem {
  padding-right: 4.875rem !important;
}

.pl-4_875rem {
  padding-left: 4.875rem !important;
}

.pb-4_875rem {
  padding-bottom: 4.875rem !important;
}

.px-4_875rem {
  padding-left: 4.875rem !important;
  padding-right: 4.875rem !important;
}

.py-4_875rem {
  padding-top: 4.875rem !important;
  padding-bottom: 4.875rem !important;
}

.-p-4_875rem {
  padding: -4.875rem !important;
}

.-pt-4_875rem {
  padding-top: -4.875rem !important;
}

.-pr-4_875rem {
  padding-right: -4.875rem !important;
}

.-pl-4_875rem {
  padding-left: -4.875rem !important;
}

.-pb-4_875rem {
  padding-bottom: -4.875rem !important;
}

.-px-4_875rem {
  padding-left: -4.875rem !important;
  padding-right: -4.875rem !important;
}

.-py-4_875rem {
  padding-top: -4.875rem !important;
  padding-bottom: -4.875rem !important;
}

.m-4_875rem {
  margin: 4.875rem !important;
}

.mt-4_875rem {
  margin-top: 4.875rem !important;
}

.mr-4_875rem {
  margin-right: 4.875rem !important;
}

.ml-4_875rem {
  margin-left: 4.875rem !important;
}

.mb-4_875rem {
  margin-bottom: 4.875rem !important;
}

.mx-4_875rem {
  margin-left: 4.875rem !important;
  margin-right: 4.875rem !important;
}

.my-4_875rem {
  margin-top: 4.875rem !important;
  margin-bottom: 4.875rem !important;
}

.-m-4_875rem {
  margin: -4.875rem !important;
}

.-mt-4_875rem {
  margin-top: -4.875rem !important;
}

.-mr-4_875rem {
  margin-right: -4.875rem !important;
}

.-ml-4_875rem {
  margin-left: -4.875rem !important;
}

.-mb-4_875rem {
  margin-bottom: -4.875rem !important;
}

.-mx-4_875rem {
  margin-left: -4.875rem !important;
  margin-right: -4.875rem !important;
}

.-my-4_875rem {
  margin-top: -4.875rem !important;
  margin-bottom: -4.875rem !important;
}

.line-height-5rem {
  line-height: 5rem !important;
}

.min-w-5rem {
  min-width: 5rem !important;
}

.min-h-5rem {
  min-height: 5rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.gap-5rem {
  gap: 5rem !important;
}

.line-height-5_25rem {
  line-height: 5.25rem !important;
}

.w-5_25rem {
  width: 5.25rem !important;
}

.min-w-5_25rem {
  min-width: 5.25rem !important;
}

.max-w-5_25rem {
  max-width: 5.25rem !important;
}

.h-5_25rem {
  height: 5.25rem !important;
}

.min-h-5_25rem {
  min-height: 5.25rem !important;
}

.max-h-5_25rem {
  max-height: 5.25rem !important;
}

.gap-5_25rem {
  gap: 5.25rem !important;
}

.p-5_25rem {
  padding: 5.25rem !important;
}

.pt-5_25rem {
  padding-top: 5.25rem !important;
}

.pr-5_25rem {
  padding-right: 5.25rem !important;
}

.pl-5_25rem {
  padding-left: 5.25rem !important;
}

.pb-5_25rem {
  padding-bottom: 5.25rem !important;
}

.px-5_25rem {
  padding-left: 5.25rem !important;
  padding-right: 5.25rem !important;
}

.py-5_25rem {
  padding-top: 5.25rem !important;
  padding-bottom: 5.25rem !important;
}

.-p-5_25rem {
  padding: -5.25rem !important;
}

.-pt-5_25rem {
  padding-top: -5.25rem !important;
}

.-pr-5_25rem {
  padding-right: -5.25rem !important;
}

.-pl-5_25rem {
  padding-left: -5.25rem !important;
}

.-pb-5_25rem {
  padding-bottom: -5.25rem !important;
}

.-px-5_25rem {
  padding-left: -5.25rem !important;
  padding-right: -5.25rem !important;
}

.-py-5_25rem {
  padding-top: -5.25rem !important;
  padding-bottom: -5.25rem !important;
}

.m-5_25rem {
  margin: 5.25rem !important;
}

.mt-5_25rem {
  margin-top: 5.25rem !important;
}

.mr-5_25rem {
  margin-right: 5.25rem !important;
}

.ml-5_25rem {
  margin-left: 5.25rem !important;
}

.mb-5_25rem {
  margin-bottom: 5.25rem !important;
}

.mx-5_25rem {
  margin-left: 5.25rem !important;
  margin-right: 5.25rem !important;
}

.my-5_25rem {
  margin-top: 5.25rem !important;
  margin-bottom: 5.25rem !important;
}

.-m-5_25rem {
  margin: -5.25rem !important;
}

.-mt-5_25rem {
  margin-top: -5.25rem !important;
}

.-mr-5_25rem {
  margin-right: -5.25rem !important;
}

.-ml-5_25rem {
  margin-left: -5.25rem !important;
}

.-mb-5_25rem {
  margin-bottom: -5.25rem !important;
}

.-mx-5_25rem {
  margin-left: -5.25rem !important;
  margin-right: -5.25rem !important;
}

.-my-5_25rem {
  margin-top: -5.25rem !important;
  margin-bottom: -5.25rem !important;
}

.line-height-5_50rem {
  line-height: 5.5rem !important;
}

.w-5_50rem {
  width: 5.5rem !important;
}

.min-w-5_50rem {
  min-width: 5.5rem !important;
}

.max-w-5_50rem {
  max-width: 5.5rem !important;
}

.h-5_50rem {
  height: 5.5rem !important;
}

.min-h-5_50rem {
  min-height: 5.5rem !important;
}

.max-h-5_50rem {
  max-height: 5.5rem !important;
}

.gap-5_50rem {
  gap: 5.5rem !important;
}

.p-5_50rem {
  padding: 5.5rem !important;
}

.pt-5_50rem {
  padding-top: 5.5rem !important;
}

.pr-5_50rem {
  padding-right: 5.5rem !important;
}

.pl-5_50rem {
  padding-left: 5.5rem !important;
}

.pb-5_50rem {
  padding-bottom: 5.5rem !important;
}

.px-5_50rem {
  padding-left: 5.5rem !important;
  padding-right: 5.5rem !important;
}

.py-5_50rem {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.-p-5_50rem {
  padding: -5.5rem !important;
}

.-pt-5_50rem {
  padding-top: -5.5rem !important;
}

.-pr-5_50rem {
  padding-right: -5.5rem !important;
}

.-pl-5_50rem {
  padding-left: -5.5rem !important;
}

.-pb-5_50rem {
  padding-bottom: -5.5rem !important;
}

.-px-5_50rem {
  padding-left: -5.5rem !important;
  padding-right: -5.5rem !important;
}

.-py-5_50rem {
  padding-top: -5.5rem !important;
  padding-bottom: -5.5rem !important;
}

.m-5_50rem {
  margin: 5.5rem !important;
}

.mt-5_50rem {
  margin-top: 5.5rem !important;
}

.mr-5_50rem {
  margin-right: 5.5rem !important;
}

.ml-5_50rem {
  margin-left: 5.5rem !important;
}

.mb-5_50rem {
  margin-bottom: 5.5rem !important;
}

.mx-5_50rem {
  margin-left: 5.5rem !important;
  margin-right: 5.5rem !important;
}

.my-5_50rem {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.-m-5_50rem {
  margin: -5.5rem !important;
}

.-mt-5_50rem {
  margin-top: -5.5rem !important;
}

.-mr-5_50rem {
  margin-right: -5.5rem !important;
}

.-ml-5_50rem {
  margin-left: -5.5rem !important;
}

.-mb-5_50rem {
  margin-bottom: -5.5rem !important;
}

.-mx-5_50rem {
  margin-left: -5.5rem !important;
  margin-right: -5.5rem !important;
}

.-my-5_50rem {
  margin-top: -5.5rem !important;
  margin-bottom: -5.5rem !important;
}

.line-height-5_75rem {
  line-height: 5.75rem !important;
}

.w-5_75rem {
  width: 5.75rem !important;
}

.min-w-5_75rem {
  min-width: 5.75rem !important;
}

.max-w-5_75rem {
  max-width: 5.75rem !important;
}

.h-5_75rem {
  height: 5.75rem !important;
}

.min-h-5_75rem {
  min-height: 5.75rem !important;
}

.max-h-5_75rem {
  max-height: 5.75rem !important;
}

.gap-5_75rem {
  gap: 5.75rem !important;
}

.p-5_75rem {
  padding: 5.75rem !important;
}

.pt-5_75rem {
  padding-top: 5.75rem !important;
}

.pr-5_75rem {
  padding-right: 5.75rem !important;
}

.pl-5_75rem {
  padding-left: 5.75rem !important;
}

.pb-5_75rem {
  padding-bottom: 5.75rem !important;
}

.px-5_75rem {
  padding-left: 5.75rem !important;
  padding-right: 5.75rem !important;
}

.py-5_75rem {
  padding-top: 5.75rem !important;
  padding-bottom: 5.75rem !important;
}

.-p-5_75rem {
  padding: -5.75rem !important;
}

.-pt-5_75rem {
  padding-top: -5.75rem !important;
}

.-pr-5_75rem {
  padding-right: -5.75rem !important;
}

.-pl-5_75rem {
  padding-left: -5.75rem !important;
}

.-pb-5_75rem {
  padding-bottom: -5.75rem !important;
}

.-px-5_75rem {
  padding-left: -5.75rem !important;
  padding-right: -5.75rem !important;
}

.-py-5_75rem {
  padding-top: -5.75rem !important;
  padding-bottom: -5.75rem !important;
}

.m-5_75rem {
  margin: 5.75rem !important;
}

.mt-5_75rem {
  margin-top: 5.75rem !important;
}

.mr-5_75rem {
  margin-right: 5.75rem !important;
}

.ml-5_75rem {
  margin-left: 5.75rem !important;
}

.mb-5_75rem {
  margin-bottom: 5.75rem !important;
}

.mx-5_75rem {
  margin-left: 5.75rem !important;
  margin-right: 5.75rem !important;
}

.my-5_75rem {
  margin-top: 5.75rem !important;
  margin-bottom: 5.75rem !important;
}

.-m-5_75rem {
  margin: -5.75rem !important;
}

.-mt-5_75rem {
  margin-top: -5.75rem !important;
}

.-mr-5_75rem {
  margin-right: -5.75rem !important;
}

.-ml-5_75rem {
  margin-left: -5.75rem !important;
}

.-mb-5_75rem {
  margin-bottom: -5.75rem !important;
}

.-mx-5_75rem {
  margin-left: -5.75rem !important;
  margin-right: -5.75rem !important;
}

.-my-5_75rem {
  margin-top: -5.75rem !important;
  margin-bottom: -5.75rem !important;
}

.line-height-5_125rem {
  line-height: 5.125rem !important;
}

.w-5_125rem {
  width: 5.125rem !important;
}

.min-w-5_125rem {
  min-width: 5.125rem !important;
}

.max-w-5_125rem {
  max-width: 5.125rem !important;
}

.h-5_125rem {
  height: 5.125rem !important;
}

.min-h-5_125rem {
  min-height: 5.125rem !important;
}

.max-h-5_125rem {
  max-height: 5.125rem !important;
}

.gap-5_125rem {
  gap: 5.125rem !important;
}

.p-5_125rem {
  padding: 5.125rem !important;
}

.pt-5_125rem {
  padding-top: 5.125rem !important;
}

.pr-5_125rem {
  padding-right: 5.125rem !important;
}

.pl-5_125rem {
  padding-left: 5.125rem !important;
}

.pb-5_125rem {
  padding-bottom: 5.125rem !important;
}

.px-5_125rem {
  padding-left: 5.125rem !important;
  padding-right: 5.125rem !important;
}

.py-5_125rem {
  padding-top: 5.125rem !important;
  padding-bottom: 5.125rem !important;
}

.-p-5_125rem {
  padding: -5.125rem !important;
}

.-pt-5_125rem {
  padding-top: -5.125rem !important;
}

.-pr-5_125rem {
  padding-right: -5.125rem !important;
}

.-pl-5_125rem {
  padding-left: -5.125rem !important;
}

.-pb-5_125rem {
  padding-bottom: -5.125rem !important;
}

.-px-5_125rem {
  padding-left: -5.125rem !important;
  padding-right: -5.125rem !important;
}

.-py-5_125rem {
  padding-top: -5.125rem !important;
  padding-bottom: -5.125rem !important;
}

.m-5_125rem {
  margin: 5.125rem !important;
}

.mt-5_125rem {
  margin-top: 5.125rem !important;
}

.mr-5_125rem {
  margin-right: 5.125rem !important;
}

.ml-5_125rem {
  margin-left: 5.125rem !important;
}

.mb-5_125rem {
  margin-bottom: 5.125rem !important;
}

.mx-5_125rem {
  margin-left: 5.125rem !important;
  margin-right: 5.125rem !important;
}

.my-5_125rem {
  margin-top: 5.125rem !important;
  margin-bottom: 5.125rem !important;
}

.-m-5_125rem {
  margin: -5.125rem !important;
}

.-mt-5_125rem {
  margin-top: -5.125rem !important;
}

.-mr-5_125rem {
  margin-right: -5.125rem !important;
}

.-ml-5_125rem {
  margin-left: -5.125rem !important;
}

.-mb-5_125rem {
  margin-bottom: -5.125rem !important;
}

.-mx-5_125rem {
  margin-left: -5.125rem !important;
  margin-right: -5.125rem !important;
}

.-my-5_125rem {
  margin-top: -5.125rem !important;
  margin-bottom: -5.125rem !important;
}

.line-height-5_375rem {
  line-height: 5.375rem !important;
}

.w-5_375rem {
  width: 5.375rem !important;
}

.min-w-5_375rem {
  min-width: 5.375rem !important;
}

.max-w-5_375rem {
  max-width: 5.375rem !important;
}

.h-5_375rem {
  height: 5.375rem !important;
}

.min-h-5_375rem {
  min-height: 5.375rem !important;
}

.max-h-5_375rem {
  max-height: 5.375rem !important;
}

.gap-5_375rem {
  gap: 5.375rem !important;
}

.p-5_375rem {
  padding: 5.375rem !important;
}

.pt-5_375rem {
  padding-top: 5.375rem !important;
}

.pr-5_375rem {
  padding-right: 5.375rem !important;
}

.pl-5_375rem {
  padding-left: 5.375rem !important;
}

.pb-5_375rem {
  padding-bottom: 5.375rem !important;
}

.px-5_375rem {
  padding-left: 5.375rem !important;
  padding-right: 5.375rem !important;
}

.py-5_375rem {
  padding-top: 5.375rem !important;
  padding-bottom: 5.375rem !important;
}

.-p-5_375rem {
  padding: -5.375rem !important;
}

.-pt-5_375rem {
  padding-top: -5.375rem !important;
}

.-pr-5_375rem {
  padding-right: -5.375rem !important;
}

.-pl-5_375rem {
  padding-left: -5.375rem !important;
}

.-pb-5_375rem {
  padding-bottom: -5.375rem !important;
}

.-px-5_375rem {
  padding-left: -5.375rem !important;
  padding-right: -5.375rem !important;
}

.-py-5_375rem {
  padding-top: -5.375rem !important;
  padding-bottom: -5.375rem !important;
}

.m-5_375rem {
  margin: 5.375rem !important;
}

.mt-5_375rem {
  margin-top: 5.375rem !important;
}

.mr-5_375rem {
  margin-right: 5.375rem !important;
}

.ml-5_375rem {
  margin-left: 5.375rem !important;
}

.mb-5_375rem {
  margin-bottom: 5.375rem !important;
}

.mx-5_375rem {
  margin-left: 5.375rem !important;
  margin-right: 5.375rem !important;
}

.my-5_375rem {
  margin-top: 5.375rem !important;
  margin-bottom: 5.375rem !important;
}

.-m-5_375rem {
  margin: -5.375rem !important;
}

.-mt-5_375rem {
  margin-top: -5.375rem !important;
}

.-mr-5_375rem {
  margin-right: -5.375rem !important;
}

.-ml-5_375rem {
  margin-left: -5.375rem !important;
}

.-mb-5_375rem {
  margin-bottom: -5.375rem !important;
}

.-mx-5_375rem {
  margin-left: -5.375rem !important;
  margin-right: -5.375rem !important;
}

.-my-5_375rem {
  margin-top: -5.375rem !important;
  margin-bottom: -5.375rem !important;
}

.line-height-5_625rem {
  line-height: 5.625rem !important;
}

.w-5_625rem {
  width: 5.625rem !important;
}

.min-w-5_625rem {
  min-width: 5.625rem !important;
}

.max-w-5_625rem {
  max-width: 5.625rem !important;
}

.h-5_625rem {
  height: 5.625rem !important;
}

.min-h-5_625rem {
  min-height: 5.625rem !important;
}

.max-h-5_625rem {
  max-height: 5.625rem !important;
}

.gap-5_625rem {
  gap: 5.625rem !important;
}

.p-5_625rem {
  padding: 5.625rem !important;
}

.pt-5_625rem {
  padding-top: 5.625rem !important;
}

.pr-5_625rem {
  padding-right: 5.625rem !important;
}

.pl-5_625rem {
  padding-left: 5.625rem !important;
}

.pb-5_625rem {
  padding-bottom: 5.625rem !important;
}

.px-5_625rem {
  padding-left: 5.625rem !important;
  padding-right: 5.625rem !important;
}

.py-5_625rem {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.-p-5_625rem {
  padding: -5.625rem !important;
}

.-pt-5_625rem {
  padding-top: -5.625rem !important;
}

.-pr-5_625rem {
  padding-right: -5.625rem !important;
}

.-pl-5_625rem {
  padding-left: -5.625rem !important;
}

.-pb-5_625rem {
  padding-bottom: -5.625rem !important;
}

.-px-5_625rem {
  padding-left: -5.625rem !important;
  padding-right: -5.625rem !important;
}

.-py-5_625rem {
  padding-top: -5.625rem !important;
  padding-bottom: -5.625rem !important;
}

.m-5_625rem {
  margin: 5.625rem !important;
}

.mt-5_625rem {
  margin-top: 5.625rem !important;
}

.mr-5_625rem {
  margin-right: 5.625rem !important;
}

.ml-5_625rem {
  margin-left: 5.625rem !important;
}

.mb-5_625rem {
  margin-bottom: 5.625rem !important;
}

.mx-5_625rem {
  margin-left: 5.625rem !important;
  margin-right: 5.625rem !important;
}

.my-5_625rem {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important;
}

.-m-5_625rem {
  margin: -5.625rem !important;
}

.-mt-5_625rem {
  margin-top: -5.625rem !important;
}

.-mr-5_625rem {
  margin-right: -5.625rem !important;
}

.-ml-5_625rem {
  margin-left: -5.625rem !important;
}

.-mb-5_625rem {
  margin-bottom: -5.625rem !important;
}

.-mx-5_625rem {
  margin-left: -5.625rem !important;
  margin-right: -5.625rem !important;
}

.-my-5_625rem {
  margin-top: -5.625rem !important;
  margin-bottom: -5.625rem !important;
}

.line-height-5_875rem {
  line-height: 5.875rem !important;
}

.w-5_875rem {
  width: 5.875rem !important;
}

.min-w-5_875rem {
  min-width: 5.875rem !important;
}

.max-w-5_875rem {
  max-width: 5.875rem !important;
}

.h-5_875rem {
  height: 5.875rem !important;
}

.min-h-5_875rem {
  min-height: 5.875rem !important;
}

.max-h-5_875rem {
  max-height: 5.875rem !important;
}

.gap-5_875rem {
  gap: 5.875rem !important;
}

.p-5_875rem {
  padding: 5.875rem !important;
}

.pt-5_875rem {
  padding-top: 5.875rem !important;
}

.pr-5_875rem {
  padding-right: 5.875rem !important;
}

.pl-5_875rem {
  padding-left: 5.875rem !important;
}

.pb-5_875rem {
  padding-bottom: 5.875rem !important;
}

.px-5_875rem {
  padding-left: 5.875rem !important;
  padding-right: 5.875rem !important;
}

.py-5_875rem {
  padding-top: 5.875rem !important;
  padding-bottom: 5.875rem !important;
}

.-p-5_875rem {
  padding: -5.875rem !important;
}

.-pt-5_875rem {
  padding-top: -5.875rem !important;
}

.-pr-5_875rem {
  padding-right: -5.875rem !important;
}

.-pl-5_875rem {
  padding-left: -5.875rem !important;
}

.-pb-5_875rem {
  padding-bottom: -5.875rem !important;
}

.-px-5_875rem {
  padding-left: -5.875rem !important;
  padding-right: -5.875rem !important;
}

.-py-5_875rem {
  padding-top: -5.875rem !important;
  padding-bottom: -5.875rem !important;
}

.m-5_875rem {
  margin: 5.875rem !important;
}

.mt-5_875rem {
  margin-top: 5.875rem !important;
}

.mr-5_875rem {
  margin-right: 5.875rem !important;
}

.ml-5_875rem {
  margin-left: 5.875rem !important;
}

.mb-5_875rem {
  margin-bottom: 5.875rem !important;
}

.mx-5_875rem {
  margin-left: 5.875rem !important;
  margin-right: 5.875rem !important;
}

.my-5_875rem {
  margin-top: 5.875rem !important;
  margin-bottom: 5.875rem !important;
}

.-m-5_875rem {
  margin: -5.875rem !important;
}

.-mt-5_875rem {
  margin-top: -5.875rem !important;
}

.-mr-5_875rem {
  margin-right: -5.875rem !important;
}

.-ml-5_875rem {
  margin-left: -5.875rem !important;
}

.-mb-5_875rem {
  margin-bottom: -5.875rem !important;
}

.-mx-5_875rem {
  margin-left: -5.875rem !important;
  margin-right: -5.875rem !important;
}

.-my-5_875rem {
  margin-top: -5.875rem !important;
  margin-bottom: -5.875rem !important;
}

.w-0 {
  width: 0 !important;
}

.h-0 {
  height: 0 !important;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-1\/2-screen {
  max-width: 50vw;
}

.max-h-1\/2 {
  max-height: 50%;
}

.max-h-1\/2-screen {
  max-height: 50vh;
}

.m-1_0px {
  margin: 0.0625rem !important;
}

.mt-1_0px {
  margin-top: 0.0625rem !important;
}

.mr-1_0px {
  margin-right: 0.0625rem !important;
}

.ml-1_0px {
  margin-left: 0.0625rem !important;
}

.mb-1_0px {
  margin-bottom: 0.0625rem !important;
}

.mx-1_0px {
  margin-left: 0.0625rem !important;
  margin-right: 0.0625rem !important;
}

.my-1_0px {
  margin-top: 0.0625rem !important;
  margin-bottom: 0.0625rem !important;
}

.-m-1_0px {
  margin: -0.0625rem !important;
}

.-mt-1_0px {
  margin-top: -0.0625rem !important;
}

.-mr-1_0px {
  margin-right: -0.0625rem !important;
}

.-ml-1_0px {
  margin-left: -0.0625rem !important;
}

.-mb-1_0px {
  margin-bottom: -0.0625rem !important;
}

.-mx-1_0px {
  margin-left: -0.0625rem !important;
  margin-right: -0.0625rem !important;
}

.-my-1_0px {
  margin-top: -0.0625rem !important;
  margin-bottom: -0.0625rem !important;
}

.p-1_0px {
  padding: 0.0625rem !important;
}

.pt-1_0px {
  padding-top: 0.0625rem !important;
}

.pr-1_0px {
  padding-right: 0.0625rem !important;
}

.pl-1_0px {
  padding-left: 0.0625rem !important;
}

.pb-1_0px {
  padding-bottom: 0.0625rem !important;
}

.px-1_0px {
  padding-left: 0.0625rem !important;
  padding-right: 0.0625rem !important;
}

.py-1_0px {
  padding-top: 0.0625rem !important;
  padding-bottom: 0.0625rem !important;
}

.-p-1_0px {
  padding: -0.0625rem !important;
}

.-pt-1_0px {
  padding-top: -0.0625rem !important;
}

.-pr-1_0px {
  padding-right: -0.0625rem !important;
}

.-pl-1_0px {
  padding-left: -0.0625rem !important;
}

.-pb-1_0px {
  padding-bottom: -0.0625rem !important;
}

.-px-1_0px {
  padding-left: -0.0625rem !important;
  padding-right: -0.0625rem !important;
}

.-py-1_0px {
  padding-top: -0.0625rem !important;
  padding-bottom: -0.0625rem !important;
}

.text-ellipsis-line-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-word !important;
  overflow: hidden;
}

.text-ellipsis-break-all-line-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-all !important;
  overflow: hidden;
}

.text-ellipsis-line-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-word !important;
  overflow: hidden;
}

.text-ellipsis-break-all-line-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-all !important;
  overflow: hidden;
}

.text-ellipsis-line-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-word !important;
  overflow: hidden;
}

.text-ellipsis-break-all-line-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-all !important;
  overflow: hidden;
}

.text-ellipsis-line-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-word !important;
  overflow: hidden;
}

.text-ellipsis-break-all-line-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-all !important;
  overflow: hidden;
}

.text-ellipsis-line-5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-word !important;
  overflow: hidden;
}

.text-ellipsis-break-all-line-5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-all !important;
  overflow: hidden;
}

.text-ellipsis-line-6 {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-word !important;
  overflow: hidden;
}

.text-ellipsis-break-all-line-6 {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  word-break: break-all !important;
  overflow: hidden;
}

.font-size-10px {
  font-size: 10px !important;
}

.font-size-11px {
  font-size: 11px !important;
}

.font-size-13px {
  font-size: 13px !important;
}

.min-h-6rem {
  min-height: 6rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.min-w-6rem {
  min-width: 6rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.min-h-7rem {
  min-height: 7rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.min-w-7rem {
  min-width: 7rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.min-h-8rem {
  min-height: 8rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.min-w-8rem {
  min-width: 8rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.min-h-9rem {
  min-height: 9rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.min-w-9rem {
  min-width: 9rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.min-h-10rem {
  min-height: 10rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.min-w-10rem {
  min-width: 10rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.min-h-11rem {
  min-height: 11rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.min-w-11rem {
  min-width: 11rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.min-h-12rem {
  min-height: 12rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.min-w-12rem {
  min-width: 12rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.min-h-13rem {
  min-height: 13rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.min-w-13rem {
  min-width: 13rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.min-h-14rem {
  min-height: 14rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.min-w-14rem {
  min-width: 14rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.min-h-15rem {
  min-height: 15rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.min-w-15rem {
  min-width: 15rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.min-h-16rem {
  min-height: 16rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.min-w-16rem {
  min-width: 16rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.min-h-17rem {
  min-height: 17rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.min-w-17rem {
  min-width: 17rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.min-h-18rem {
  min-height: 18rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.min-w-18rem {
  min-width: 18rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.min-h-19rem {
  min-height: 19rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.min-w-19rem {
  min-width: 19rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.min-h-20rem {
  min-height: 20rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.min-w-20rem {
  min-width: 20rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.min-h-21rem {
  min-height: 21rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.min-w-21rem {
  min-width: 21rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.min-h-22rem {
  min-height: 22rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.min-w-22rem {
  min-width: 22rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.min-h-23rem {
  min-height: 23rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.min-w-23rem {
  min-width: 23rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.min-h-24rem {
  min-height: 24rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.min-w-24rem {
  min-width: 24rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.min-h-25rem {
  min-height: 25rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.min-w-25rem {
  min-width: 25rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.min-h-26rem {
  min-height: 26rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.min-w-26rem {
  min-width: 26rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.min-h-27rem {
  min-height: 27rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.min-w-27rem {
  min-width: 27rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.min-h-28rem {
  min-height: 28rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.min-w-28rem {
  min-width: 28rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.min-h-30rem {
  min-height: 30rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

.min-w-30rem {
  min-width: 30rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

.min-h-32rem {
  min-height: 32rem !important;
}

.max-h-32rem {
  max-height: 32rem !important;
}

.min-w-32rem {
  min-width: 32rem !important;
}

.max-w-32rem {
  max-width: 32rem !important;
}

.min-h-34rem {
  min-height: 34rem !important;
}

.max-h-34rem {
  max-height: 34rem !important;
}

.min-w-34rem {
  min-width: 34rem !important;
}

.max-w-34rem {
  max-width: 34rem !important;
}

.min-h-36rem {
  min-height: 36rem !important;
}

.max-h-36rem {
  max-height: 36rem !important;
}

.min-w-36rem {
  min-width: 36rem !important;
}

.max-w-36rem {
  max-width: 36rem !important;
}

.min-h-29rem {
  min-height: 29rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.min-w-29rem {
  min-width: 29rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.min-h-30rem {
  min-height: 30rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

.min-w-30rem {
  min-width: 30rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

.hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.-translate-y-50 {
  transform: translateY(-50%) !important;
}

.-translate-x-50 {
  transform: translateX(-50%) !important;
}

.hover\:opacity-100:hover {
  opacity: 100% !important;
}

.bg-deepblue-300 {
  background-color: #d2dcfc;
}

.bg-mellow-mint {
  background-color: #deedbf;
}

[pinnaklhighlight] h1,
[pinnaklhighlight] h2,
[pinnaklhighlight] h3,
[pinnaklhighlight] h4,
[pinnaklhighlight] h5,
[pinnaklhighlight] h6 {
  font-size: inherit !important;
}
[pinnaklhighlight] mark {
  background-color: transparent;
  line-height: 1;
  padding: 0;
}

.content-bottom-faded:after {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(180deg, transparent 0%, transparent 60%, white 100%);
}
.content-bottom-faded.bg-orange-100:after {
  background: linear-gradient(180deg, transparent 0%, transparent 60%, rgba(var(--orange-100), 1) 100%);
}

.not-visible {
  visibility: hidden !important;
}

.bg-none {
  background: none;
}

.text-error {
  color: var(--error-color);
}

.chips-container {
  flex-wrap: nowrap !important;
}
.chips-container .chip {
  max-width: calc(100% - 50px);
}
.chips-container .counter {
  max-width: 50px;
}
.chips-container.show-chips {
  flex-wrap: wrap !important;
  max-height: initial !important;
}
.chips-container.show-chips .chip {
  max-width: 100%;
}
.chips-container.show-chips .not-visible {
  visibility: visible !important;
}

.pinnakl-flag {
  display: block;
  min-width: 1rem;
  height: auto;
  aspect-ratio: 1/1;
  background: url(/assets/flags_responsive.png) no-repeat;
  background-size: 150%;
  border-radius: 50%;
  background-position: 50% 0;
}

.flag-ad {
  background-position: 50% 0.413223%;
}

.flag-ae {
  background-position: 50% 0.826446%;
}

.flag-af {
  background-position: 50% 1.239669%;
}

.flag-ag {
  background-position: 50% 1.652893%;
}

.flag-ai {
  background-position: 50% 2.066116%;
}

.flag-al {
  background-position: 50% 2.479339%;
}

.flag-am {
  background-position: 50% 2.892562%;
}

.flag-an {
  background-position: 50% 3.305785%;
}

.flag-ao {
  background-position: 50% 3.719008%;
}

.flag-aq {
  background-position: 50% 4.132231%;
}

.flag-ar {
  background-position: 50% 4.545455%;
}

.flag-as {
  background-position: 50% 4.958678%;
}

.flag-at {
  background-position: 50% 5.371901%;
}

.flag-au {
  background-position: 50% 5.785124%;
}

.flag-aw {
  background-position: 50% 6.198347%;
}

.flag-az {
  background-position: 50% 6.61157%;
}

.flag-ba {
  background-position: 50% 7.024793%;
}

.flag-bb {
  background-position: 50% 7.438017%;
}

.flag-bd {
  background-position: 50% 7.85124%;
}

.flag-be {
  background-position: 50% 8.264463%;
}

.flag-bf {
  background-position: 50% 8.677686%;
}

.flag-bg {
  background-position: 50% 9.090909%;
}

.flag-bh {
  background-position: 50% 9.504132%;
}

.flag-bi {
  background-position: 50% 9.917355%;
}

.flag-bj {
  background-position: 50% 10.330579%;
}

.flag-bm {
  background-position: 50% 10.743802%;
}

.flag-bn {
  background-position: 50% 11.157025%;
}

.flag-bo {
  background-position: 50% 11.570248%;
}

.flag-br {
  background-position: 50% 11.983471%;
}

.flag-bs {
  background-position: 50% 12.396694%;
}

.flag-bt {
  background-position: 50% 12.809917%;
}

.flag-bv {
  background-position: 50% 13.22314%;
}

.flag-bw {
  background-position: 50% 13.636364%;
}

.flag-by {
  background-position: 50% 14.049587%;
}

.flag-bz {
  background-position: 50% 14.46281%;
}

.flag-ca {
  background-position: 50% 14.876033%;
}

.flag-cc {
  background-position: 50% 15.289256%;
}

.flag-cd {
  background-position: 50% 15.702479%;
}

.flag-cf {
  background-position: 50% 16.115702%;
}

.flag-cg {
  background-position: 50% 16.528926%;
}

.flag-ch {
  background-position: 50% 16.942149%;
}

.flag-ci {
  background-position: 50% 17.355372%;
}

.flag-ck {
  background-position: 50% 17.768595%;
}

.flag-cl {
  background-position: 50% 18.181818%;
}

.flag-cm {
  background-position: 50% 18.595041%;
}

.flag-cn {
  background-position: 50% 19.008264%;
}

.flag-co {
  background-position: 50% 19.421488%;
}

.flag-cr {
  background-position: 50% 19.834711%;
}

.flag-cu {
  background-position: 50% 20.247934%;
}

.flag-cv {
  background-position: 50% 20.661157%;
}

.flag-cx {
  background-position: 50% 21.07438%;
}

.flag-cy {
  background-position: 50% 21.487603%;
}

.flag-cz {
  background-position: 50% 21.900826%;
}

.flag-de {
  background-position: 50% 22.31405%;
}

.flag-dj {
  background-position: 50% 22.727273%;
}

.flag-dk {
  background-position: 50% 23.140496%;
}

.flag-dm {
  background-position: 50% 23.553719%;
}

.flag-do {
  background-position: 50% 23.966942%;
}

.flag-dz {
  background-position: 50% 24.380165%;
}

.flag-ec {
  background-position: 50% 24.793388%;
}

.flag-ee {
  background-position: 50% 25.206612%;
}

.flag-eg {
  background-position: 50% 25.619835%;
}

.flag-eh {
  background-position: 50% 26.033058%;
}

.flag-er {
  background-position: 50% 26.446281%;
}

.flag-es {
  background-position: 50% 26.859504%;
}

.flag-et {
  background-position: 50% 27.272727%;
}

.flag-fi {
  background-position: 50% 27.68595%;
}

.flag-fj {
  background-position: 50% 28.099174%;
}

.flag-fk {
  background-position: 50% 28.512397%;
}

.flag-fm {
  background-position: 50% 28.92562%;
}

.flag-fo {
  background-position: 50% 29.338843%;
}

.flag-fr {
  background-position: 50% 29.752066%;
}

.flag-ga {
  background-position: 50% 30.165289%;
}

.flag-gd {
  background-position: 50% 30.578512%;
}

.flag-ge {
  background-position: 50% 30.991736%;
}

.flag-gf {
  background-position: 50% 31.404959%;
}

.flag-gh {
  background-position: 50% 31.818182%;
}

.flag-gi {
  background-position: 50% 32.231405%;
}

.flag-gl {
  background-position: 50% 32.644628%;
}

.flag-gm {
  background-position: 50% 33.057851%;
}

.flag-gn {
  background-position: 50% 33.471074%;
}

.flag-gp {
  background-position: 50% 33.884298%;
}

.flag-gq {
  background-position: 50% 34.297521%;
}

.flag-gr {
  background-position: 50% 34.710744%;
}

.flag-gs {
  background-position: 50% 35.123967%;
}

.flag-gt {
  background-position: 50% 35.53719%;
}

.flag-gu {
  background-position: 50% 35.950413%;
}

.flag-gw {
  background-position: 50% 36.363636%;
}

.flag-gy {
  background-position: 50% 36.77686%;
}

.flag-hk {
  background-position: 50% 37.190083%;
}

.flag-hm {
  background-position: 50% 37.603306%;
}

.flag-hn {
  background-position: 50% 38.016529%;
}

.flag-hr {
  background-position: 50% 38.429752%;
}

.flag-ht {
  background-position: 50% 38.842975%;
}

.flag-hu {
  background-position: 50% 39.256198%;
}

.flag-id {
  background-position: 50% 39.669421%;
}

.flag-ie {
  background-position: 50% 40.082645%;
}

.flag-il {
  background-position: 50% 40.495868%;
}

.flag-in {
  background-position: 50% 40.909091%;
}

.flag-io {
  background-position: 50% 41.322314%;
}

.flag-iq {
  background-position: 50% 41.735537%;
}

.flag-ir {
  background-position: 50% 42.14876%;
}

.flag-is {
  background-position: 50% 42.561983%;
}

.flag-it {
  background-position: 50% 42.975207%;
}

.flag-jm {
  background-position: 50% 43.38843%;
}

.flag-jo {
  background-position: 50% 43.801653%;
}

.flag-jp {
  background-position: 50% 44.214876%;
}

.flag-ke {
  background-position: 50% 44.628099%;
}

.flag-kg {
  background-position: 50% 45.041322%;
}

.flag-kh {
  background-position: 50% 45.454545%;
}

.flag-ki {
  background-position: 50% 45.867769%;
}

.flag-km {
  background-position: 50% 46.280992%;
}

.flag-kn {
  background-position: 50% 46.694215%;
}

.flag-kp {
  background-position: 50% 47.107438%;
}

.flag-kr {
  background-position: 50% 47.520661%;
}

.flag-kw {
  background-position: 50% 47.933884%;
}

.flag-ky {
  background-position: 50% 48.347107%;
}

.flag-kz {
  background-position: 50% 48.760331%;
}

.flag-la {
  background-position: 50% 49.173554%;
}

.flag-lb {
  background-position: 50% 49.586777%;
}

.flag-lc {
  background-position: 50% 50%;
}

.flag-li {
  background-position: 50% 50.413223%;
}

.flag-lk {
  background-position: 50% 50.826446%;
}

.flag-lr {
  background-position: 50% 51.239669%;
}

.flag-ls {
  background-position: 50% 51.652893%;
}

.flag-lt {
  background-position: 50% 52.066116%;
}

.flag-lu {
  background-position: 50% 52.479339%;
}

.flag-lv {
  background-position: 50% 52.892562%;
}

.flag-ly {
  background-position: 50% 53.305785%;
}

.flag-ma {
  background-position: 50% 53.719008%;
}

.flag-mc {
  background-position: 50% 54.132231%;
}

.flag-md {
  background-position: 50% 54.545455%;
}

.flag-me {
  background-position: 50% 54.958678%;
}

.flag-mg {
  background-position: 50% 55.371901%;
}

.flag-mh {
  background-position: 50% 55.785124%;
}

.flag-mk {
  background-position: 50% 56.198347%;
}

.flag-ml {
  background-position: 50% 56.61157%;
}

.flag-mm {
  background-position: 50% 57.024793%;
}

.flag-mn {
  background-position: 50% 57.438017%;
}

.flag-mo {
  background-position: 50% 57.85124%;
}

.flag-mp {
  background-position: 50% 58.264463%;
}

.flag-mq {
  background-position: 50% 58.677686%;
}

.flag-mr {
  background-position: 50% 59.090909%;
}

.flag-ms {
  background-position: 50% 59.504132%;
}

.flag-mt {
  background-position: 50% 59.917355%;
}

.flag-mu {
  background-position: 50% 60.330579%;
}

.flag-mv {
  background-position: 50% 60.743802%;
}

.flag-mw {
  background-position: 50% 61.157025%;
}

.flag-mx {
  background-position: 50% 61.570248%;
}

.flag-my {
  background-position: 50% 61.983471%;
}

.flag-mz {
  background-position: 50% 62.396694%;
}

.flag-na {
  background-position: 50% 62.809917%;
}

.flag-nc {
  background-position: 50% 63.22314%;
}

.flag-ne {
  background-position: 50% 63.636364%;
}

.flag-nf {
  background-position: 50% 64.049587%;
}

.flag-ng {
  background-position: 50% 64.46281%;
}

.flag-ni {
  background-position: 50% 64.876033%;
}

.flag-nl {
  background-position: 50% 65.289256%;
}

.flag-no {
  background-position: 50% 65.702479%;
}

.flag-np {
  background-position: 50% 66.115702%;
}

.flag-nr {
  background-position: 50% 66.528926%;
}

.flag-nu {
  background-position: 50% 66.942149%;
}

.flag-nz {
  background-position: 50% 67.355372%;
}

.flag-om {
  background-position: 50% 67.768595%;
}

.flag-pa {
  background-position: 50% 68.181818%;
}

.flag-pe {
  background-position: 50% 68.595041%;
}

.flag-pf {
  background-position: 50% 69.008264%;
}

.flag-pg {
  background-position: 50% 69.421488%;
}

.flag-ph {
  background-position: 50% 69.834711%;
}

.flag-pk {
  background-position: 50% 70.247934%;
}

.flag-pl {
  background-position: 50% 70.661157%;
}

.flag-pm {
  background-position: 50% 71.07438%;
}

.flag-pn {
  background-position: 50% 71.487603%;
}

.flag-pr {
  background-position: 50% 71.900826%;
}

.flag-pt {
  background-position: 50% 72.31405%;
}

.flag-pw {
  background-position: 50% 72.727273%;
}

.flag-py {
  background-position: 50% 73.140496%;
}

.flag-qa {
  background-position: 50% 73.553719%;
}

.flag-re {
  background-position: 50% 73.966942%;
}

.flag-ro {
  background-position: 50% 74.380165%;
}

.flag-rs {
  background-position: 50% 74.793388%;
}

.flag-ru {
  background-position: 50% 75.206612%;
}

.flag-rw {
  background-position: 50% 75.619835%;
}

.flag-sa {
  background-position: 50% 76.033058%;
}

.flag-sb {
  background-position: 50% 76.446281%;
}

.flag-sc {
  background-position: 50% 76.859504%;
}

.flag-sd {
  background-position: 50% 77.272727%;
}

.flag-se {
  background-position: 50% 77.68595%;
}

.flag-sg {
  background-position: 50% 78.099174%;
}

.flag-sh {
  background-position: 50% 78.512397%;
}

.flag-si {
  background-position: 50% 78.92562%;
}

.flag-sj {
  background-position: 50% 79.338843%;
}

.flag-sk {
  background-position: 50% 79.752066%;
}

.flag-sl {
  background-position: 50% 80.165289%;
}

.flag-sm {
  background-position: 50% 80.578512%;
}

.flag-sn {
  background-position: 50% 80.991736%;
}

.flag-so {
  background-position: 50% 81.404959%;
}

.flag-sr {
  background-position: 50% 81.818182%;
}

.flag-ss {
  background-position: 50% 82.231405%;
}

.flag-st {
  background-position: 50% 82.644628%;
}

.flag-sv {
  background-position: 50% 83.057851%;
}

.flag-sy {
  background-position: 50% 83.471074%;
}

.flag-sz {
  background-position: 50% 83.884298%;
}

.flag-tc {
  background-position: 50% 84.297521%;
}

.flag-td {
  background-position: 50% 84.710744%;
}

.flag-tf {
  background-position: 50% 85.123967%;
}

.flag-tg {
  background-position: 50% 85.53719%;
}

.flag-th {
  background-position: 50% 85.950413%;
}

.flag-tj {
  background-position: 50% 86.363636%;
}

.flag-tk {
  background-position: 50% 86.77686%;
}

.flag-tl {
  background-position: 50% 87.190083%;
}

.flag-tm {
  background-position: 50% 87.603306%;
}

.flag-tn {
  background-position: 50% 88.016529%;
}

.flag-to {
  background-position: 50% 88.429752%;
}

.flag-tp {
  background-position: 50% 88.842975%;
}

.flag-tr {
  background-position: 50% 89.256198%;
}

.flag-tt {
  background-position: 50% 89.669421%;
}

.flag-tv {
  background-position: 50% 90.082645%;
}

.flag-tw {
  background-position: 50% 90.495868%;
}

.flag-ty {
  background-position: 50% 90.909091%;
}

.flag-tz {
  background-position: 50% 91.322314%;
}

.flag-ua {
  background-position: 50% 91.735537%;
}

.flag-ug {
  background-position: 50% 92.14876%;
}

.flag-gb,
.flag-uk {
  background-position: 50% 92.561983%;
}

.flag-um {
  background-position: 50% 92.975207%;
}

.flag-us {
  background-position: 50% 93.38843%;
}

.flag-uy {
  background-position: 50% 93.801653%;
}

.flag-uz {
  background-position: 50% 94.214876%;
}

.flag-va {
  background-position: 50% 94.628099%;
}

.flag-vc {
  background-position: 50% 95.041322%;
}

.flag-ve {
  background-position: 50% 95.454545%;
}

.flag-vg {
  background-position: 50% 95.867769%;
}

.flag-vi {
  background-position: 50% 96.280992%;
}

.flag-vn {
  background-position: 50% 96.694215%;
}

.flag-vu {
  background-position: 50% 97.107438%;
}

.flag-wf {
  background-position: 50% 97.520661%;
}

.flag-ws {
  background-position: 50% 97.933884%;
}

.flag-ye {
  background-position: 50% 98.347107%;
}

.flag-za {
  background-position: 50% 98.760331%;
}

.flag-zm {
  background-position: 50% 99.173554%;
}

.flag-zr {
  background-position: 50% 99.586777%;
}

.flag-zw {
  background-position: 50% 100%;
}

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #262a32;
  --text-color-secondary: #646f85;
  --layout-black: #0b1017;
  --primary-color: #2196f3;
  --primary-500: #168eff;
  --primary-color-text: #ffffff;
  --font-family: "Source Sans Pro", sans-serif;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f6f6f6;
  --surface-200: #ececec;
  --surface-300: #dddddd;
  --surface-400: #cbcbcb;
  --surface-500: #aaaaaa;
  --surface-600: #959595;
  --surface-700: #6f6f6f;
  --surface-800: #3c3c3c;
  --surface-900: #292929;
  --red-50: #fff9f9;
  --red-100: #fff3f3;
  --red-200: #ffe7e7;
  --red-300: #ffd2d2;
  --red-400: #ffbaba;
  --red-500: #ff8686;
  --red-600: #ff5e5e;
  --red-700: #e10000;
  --red-800: #7f0000;
  --red-900: #5b0000;
  --bluegray-50: #f9fafb;
  --bluegray-100: #f4f5f7;
  --bluegray-200: #eaecef;
  --bluegray-300: #dadde3;
  --bluegray-400: #c7ccd5;
  --bluegray-500: #a1a9b9;
  --bluegray-600: #8b95a8;
  --bluegray-700: #646f85;
  --bluegray-800: #373d49;
  --bluegray-900: #262a32;
  --gray-50: #f9fafb;
  --gray-100: #f4f5f7;
  --gray-200: #eaecef;
  --gray-300: #dadde3;
  --gray-400: #c7ccd5;
  --gray-500: #a1a9b9;
  --gray-600: #8b95a8;
  --gray-700: #646f85;
  --gray-800: #373d49;
  --gray-900: #262a32;
  --blue-50: #f7fbff;
  --blue-100: #eef7ff;
  --blue-200: #dceeff;
  --blue-300: #c1e1ff;
  --blue-400: #a0d1ff;
  --blue-500: #59afff;
  --blue-600: #2a98ff;
  --blue-700: #006ed5;
  --blue-800: #003c74;
  --blue-900: #002950;
  --green-50: #f5fcf7;
  --green-100: #eaf9ef;
  --green-200: #d5f3df;
  --green-300: #b1e9c5;
  --green-400: #89dea7;
  --green-500: #36c167;
  --green-600: #30aa5a;
  --green-700: #247f44;
  --green-800: #134525;
  --green-900: #0d2f19;
  --orange-50: #fffaf4;
  --orange-100: #fff4e8;
  --orange-200: #ffe9d0;
  --orange-300: #ffd6a7;
  --orange-400: #ffbf76;
  --orange-500: #ff8c09;
  --orange-600: #e37900;
  --orange-700: #aa5b00;
  --orange-800: #5c3100;
  --orange-900: #3f2200;
  --yellow-500: #ffb200;
  --indigo-400: #8183f4;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 8px;
  --super-radius: 100px;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #e3f2fd;
  --highlight-text-color: #495057;
  --focus-ring: none;
  --input-border-color: #c9c9cc;
  --error-color: #ff0000;
  color-scheme: light;
}

.bordered-list-style-table.ag-theme-business {
  --ag-data-color: #262a32;
}
.bordered-list-style-table.with-hover-icons .ag-row .on-hover-icons {
  padding: 0 4px;
}
.bordered-list-style-table.with-hover-icons .ag-row .on-hover-icons i {
  visibility: hidden;
}
.bordered-list-style-table.with-hover-icons .ag-row:hover {
  cursor: pointer;
  transition: all 0.4s;
  background: var(--bluegray-50);
  border: 1px solid var(--bluegray-500);
  box-shadow: 0 1px 3px 0 var(--bluegray-200);
}
.bordered-list-style-table.with-hover-icons .ag-row:hover .on-hover-icons i {
  visibility: visible;
}
.bordered-list-style-table .ag-root-wrapper .ag-header-cell.no-resize-splitter:before {
  display: none;
}
.bordered-list-style-table .ag-root-wrapper .ag-header-cell:hover {
  background: none !important;
}
.bordered-list-style-table .ag-root-wrapper .ag-header-cell-text {
  font-size: 11px;
  letter-spacing: 0.08em;
  font-weight: 600;
  line-height: 1rem;
  color: var(--bluegray-700);
}
.bordered-list-style-table .ag-root-wrapper .ag-root-wrapper-body,
.bordered-list-style-table .ag-root-wrapper .ag-header {
  border: none;
}
.bordered-list-style-table .ag-root-wrapper .ag-ltr .ag-header-cell::after {
  display: none;
}
.bordered-list-style-table .ag-root-wrapper .ag-row {
  transition: all 0.4s;
  margin-top: 8px;
  border-radius: 12px;
  border: 1px solid var(--bluegray-200);
}
.bordered-list-style-table .ag-root-wrapper .ag-row:before {
  border-radius: 12px;
}
.bordered-list-style-table .ag-root-wrapper .ag-row .ag-cell {
  border: none;
}
.bordered-list-style-table .ag-root-wrapper .ag-group-value {
  align-self: center;
}

/* Breakpoints */
/* 421px and less */
/* 600px and less */
/* 992px and less */
/* 1024px and less */
/* 1400px and less */
/* 1600px and less */
/* 1920px and less */
/* 1921px and more */
/* Breakpoints */
/* 421px and less */
/* 600px and less */
/* 992px and less */
/* 1024px and less */
/* 1400px and less */
/* 1600px and less */
/* 1920px and less */
/* 1921px and more */
/* Different devices resolutions */