/* CHECKBOX AND RADIO */
$check-color: #eaecef;

.check-holder {
  input {
    display: none;
  }

  input + label {
    font-weight: normal;
    cursor: pointer;
    padding-left: 40px;
    position: relative;
    margin-bottom: 0;

    &:before {
      width: 15px;
      height: 15px;
      font-size: 20px;
      color: $check-color;
      font-family: 'fontello';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      @extend .icon-pinnakl-check-empty;
      line-height: 100%;
    }
  }

  input:checked + label {
    &:before {
      @extend .icon-pinnakl-check;
    }
  }

  &.check-holder-empty {
    input + label {
      padding-left: 0;
      width: 15px;
      height: 15px;
    }
  }
}

.input-check-switch input[type='checkbox'] {
  display: inline !important;
  background: none !important;
}

.toggle {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}

.switch-label {
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  flex: 0 0 auto;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c3c7cd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #168eff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #168eff;
}

.indeterminate-input {
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(184, 43, 43);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:indeterminate + .slider {
    background-color: #b3b3b3;
  }

  input:checked + .slider {
    background-color: #2db304;
  }

  input:indeterminate + .slider:before {
    -webkit-transform: translateX(12.5px);
    -ms-transform: translateX(12.5px);
    transform: translateX(12.5px);
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.green-checkbox {
  width: 16px;
  height: 16px;
  position: relative;

  input {
    display: none;
  }

  .custom-input {
    width: 16px;
    height: 16px;
    border: 1px solid #c3c7cd;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 0;
    // padding-top: 3px;
    // padding-left: 2px;
    // margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:checked + .custom-input {
    // border: 1px solid #00c94a;
    border: none;
    position: absolute;
    background-color: #00c94a;
    margin-top: 0;

    &:before {
      font-size: 9px;
      color: #fff;
      font-family: 'fontello';
      height: 10px;
      @extend .icon-pinnakl-ok;
      line-height: 100%;
    }
  }
}
